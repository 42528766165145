.SecondaryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  align-items: center;
  column-gap: 30px;
  row-gap: 50px;
  color: var(--mainBlack);
}
.PrimaryContainer {
  width: 80%;
  margin: 0 auto;
}
.NoDataAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.NoDataAvailable h2 {
  color: var(--mainBlack);
  text-align: start;
}

.NoDataAvailable h2::after {
  display: none;
}

.NoDataAvailable img {
  width: 400px;
}
 
@media (max-width: 850px) {
  .NoDataAvailable img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .NoDataAvailable img {
    width: 200px;
  }
}
