.PaytrailButton{
   height: 100%;
   width: 100%;
    padding: 10px;
  
}
.PaytrailForm{
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));
  align-items: center;
  column-gap: 30px;
  row-gap: 50px;
  color: var(--mainBlack);
  /* justify-content: center; */
}

.PaytrailGroups{
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.paymentMethodTitle{
    margin: 50px auto;
  color: var(--brand);
  font-weight: 800;

}

.terms{
    color: var(--mainBlack);
     margin: 50px auto;
  

}
.terms a{
    color: var(--brand);
}


@media (max-width:550px) {
  
    .PaytrailForm{
        display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 150px));
      gap: 30px;
      /* justify-content: center; */
    }
    .paymentMethodTitle{
        margin: 20px auto;
      
    
    }
  }