@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;800&display=swap);
body {
  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --mainWhite:  #ffffff;
  --white:  #ffffff;
  --black:  #666666;
  --mainBlack:  #666666;

  --background: white;
  --playButton: #ffdd00;

  --red:#97010E;


  --disabledText: #a1a9b0;


  --brand: #3aaa35;
  --brandHover: #31912d ;
  --brandPressed:  #008dc4;










  --font44: 44px;
  --font40: 40px;
  --font39: 39px;
  --font38: 38px;
  --font37: 37px;
  --font36: 36px;
  --font35: 35px;
  --font34: 34px;
  --font33: 33px;
  --font32: 32px;
  --font31: 31px;
  --font30: 30px;
  --font29: 29px;
  --font28: 28px;
  --font27: 27px;
  --font26: 26px;
  --font25: 25px;
  --font24: 24px;
  --font23: 23px;
  --font22: 22px;
  --font21: 21px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;
  --font10: 10px;
  --font9: 9px;
  --font8: 8px;
  --font7: 7px;
  --font6: 6px;
  --font5: 5px;
}

.itemTitleHeadingColor {
  color: var(--itemTitle);
}
.itemLinksColor {
  color: #3aaa35;
  color: var(--brand);
}
.font-800 {
  font-size: 44px;
  font-size: var(--font44);
  font-weight: 600;
  opacity: 1 !important;
  margin-bottom: 20px;
}

.font-700 {
  font-size: 36px;
  font-size: var(--font36);
}
.font-600 {
  font-size: 24px;
  font-size: var(--font24);
}

.font-500 {
  font-size: 20px;
  font-size: var(--font20);
  line-height: 20px;
}

.font-400 {
  font-size: 18px;
  font-size: var(--font18);
}

.font-300 {
  font-size: 16px;
  font-size: var(--font16);
}
.font-200 {
  font-size: 14px;
  font-size: var(--font14);
}
.font-100 {
  font-size: 12px;
  font-size: var(--font12);
}
.font-000 {
  font-size: 11px;
  font-size: var(--font11);
}

h2 {
  font-size: 24px;
  font-size: var(--font24);
  margin-bottom: 15px;
  display: block;
  /* text-align: center; */
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}
h1,
h3 {
  font-size: 36px;
  font-size: var(--font36);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
}

h2::after,
h1::after {
  content: '';
  width: 120px;
  height: 4px;
  top: -1rem;
  display: block;
  background-color: #3aaa35;
  background-color: var(--brand);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

/* Editor Choice background */
.categoryItemEditorChoice {
  background-color: #ffffff;
  background-color: var(--white);
  height: 250px;
  min-height: 250px;
  max-height: 250px;
}
.categoryItem_no_margin {
  margin: 0 !important;
}
/* modal */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 90;
  background-color: var(--cardbackground1);
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
}
.modal > h2 {
  padding: 20px;
  margin: 0;
  color: #3aaa35;
  color: var(--brand);
  /* border-bottom: 1px solid #555; */
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.copy-popup {
  background: #666666;
  background: var(--mainBlack);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  min-width: 60%;
  min-height: 40vh;
}
/* RMP CONTAINER */
#rmpPlayer {
  /* height: 100vh !important; */
  /* width: 100vw !important; */
}
.addSeecondary ul {
  -webkit-transform: none !important;
          transform: none !important;
}
#rmpLivePlayer {
  /* height: 90vh !important;
  width: 100vw !important; */
}

.rmpContainer {
  /* height: 100vh !important;
  width: 100vw !important; */
}
.bannerImage{
  background: linear-gradient(250deg,#31912d 10%,black 100.47%);
  background: linear-gradient(250deg,var(--brandHover) 10%,black 100.47%);
}


button {
  padding: 10px 56px;
  text-decoration: none;
  border: none;
  font-size: 16px;
  font-size: var(--font16);
  border-radius: 12px;
}
.itemTitleHeading{
  color: #3aaa35;
  color: var(--brand);

}
button:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
button:disabled,.button:disabled:hover {
  background: #a1a9b0;
  background: var(--disabledText);
  color: #ffdd00;
  color: var(--playButton);
  cursor: not-allowed;
}
 

/* changing right and left button in banner color */
/* These are not banner carousel */
.carousel.carousel-slider .control-arrow:hover {
  color: #3aaa35 !important;
  color: var(--brand) !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

.carousel .slide {
  /* padding: 0 20px; */
}

.hero-container {
  width: 95%;
  margin: 0 auto;
}

/* // image wrapper for aspect-ratio 16/9
 */
.image_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.image_wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.homePageContainer {
  width: 94%;
  margin: 0 0 0 6%;
}
/* media Queeries */
@media (max-width: 850px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    font-size: 22px;
    font-size: var(--font22);
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: 13px;
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }
  .hero-container {
    width: 100%;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  }
}
@media (max-width: 700px) {
  .font-800 {
    font-size: 20px;
    font-size: var(--font20);
  }
  .font-700 {
    font-size: 16px;
    font-size: var(--font16);
  }
  .font-600 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-500 {
    font-size: 13px;
    font-size: var(--font13);
  }
}
/* media Queeries */
@media (max-width: 600px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    font-size: 22px;
    font-size: var(--font22);
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: 13px;
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }

  /* button {
    padding: 18px 20px;
  } */
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .overlay_blur {
  background-image: linear-gradient(
    270deg,
    #3aaa35 0%,
    rgba(18, 22, 29, 0.08) 100%
  );
  background-image: linear-gradient(
    270deg,
    var(--brand) 0%,
    rgba(18, 22, 29, 0.08) 100%
  );

  opacity: 1;

  z-index: 100000;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .playicon_overlay {
  display: none;
}

/* Banner carousel */

.carousel .control-prev.control-arrow {
  left: 30px !important;
}
.carousel .control-next.control-arrow {
  right: 30px !important;
}
.react-multiple-carousel__arrow {
  padding: inherit;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  top: 30%;
}
/* // banner slider custom button */
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  top: 50% !important;
  min-width: 43px !important;
  min-height: 43px !important;

  height: 43px !important;
  border-radius: 50% !important;
  background: rgba(58, 18, 18, 0.7) !important;
  opacity: 0.8 !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin: 0 !important;
  font-family: 'revicons' !important;
}
.carousel .control-next.control-arrow:before {
  content: '\e825' !important;
  border: none !important;
}
.carousel .control-prev.control-arrow:before {
  content: '\e824' !important;
  border: none !important;
}

/* // navigation mobile menu */
ul.main-menu {
  display: inline-flex;
  list-style: none;
  z-index: 7;
  
}

.menu-item a {
  position: relative;
  display: block;
  color: #666666;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}

.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}
.languageSelectMobile {
  border-top: 1px solid #3aaa35;
  border-top: 1px solid var(--brand);
  width: 90%;
  margin: 10px auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 !important;
}
.action {
  display: flex;
}
.action > * + * {
  margin-left: 30px;
}
.activeProfileHeader {
    color: #ffdd00;
    color: var(--playButton);
    border-bottom: 3px solid #ffdd00;
    border-bottom: 3px solid var(--playButton);
}

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a {
  background-color: #31912d !important;
  background-color: var(--brandHover) !important;
  width: 100px;height: 50px;
  /* width: 200px;
  min-width: 200px; */
 
}
.menu-item .sub__menus li:hover a {
  color: #31912d !important;
  color: var(--brandHover) !important;
}
.navBarSiteLogoMobile {
  display: none;
}
.navBarSiteLogoMobile-lgScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
    .navBarSiteLogoBig{
      display: none;
    }
    /* // navigation mobile menu */
ul.main-menu {
   /* // navigation mobile menu */
padding-top: 50px;
margin-top: 20px;
  
    
  }
  .menu-item:hover > a {
  
    width: 200px;
    min-width: 200px;
   
  }
  
    .navBarSiteLogoMobile-lgScreen {
      /* display: none; */
    }
    .navBarSiteLogoMobile {
      display: flex;
      /* margin: 20px 0; */
      flex: 1 1;
      justify-content: center;
      align-items: center;
    }
    .navBarSiteLogoMobileFooter {
      margin: 0;
    }
    .action {
      flex: 1 1;
    }
    .action .navBarSiteLogoMobile {
      flex: 0 1;
    }
    .navBarSiteLogoMobile-img {
        height: 75px;
        position: absolute;
        top: -85px;
    }
    .main-nav .menubar__button:hover {
      color: #ffdd00;
      color: var(--playButton);
    }
    .main-nav .menu-item:hover > a {
      color: #ffdd00 !important;
      color: var(--playButton) !important;
    }
    .main-nav .menubar__button {
      display: block !important;
      float: right;
      /* background-color: var(--brand); */
      /* color: #fff; */
      color: #ffffff;
      color: var(--mainWhite);
      padding: 6px 7px;
      border-radius: 5px;
      margin: auto 0;
      cursor: pointer;
      position: relative;
      z-index: 10037;
    }
    .main-nav .menubar__button svg {
      font-size: 27px;
    }
    .main-nav ul.menuq2 {
      display: flex !important;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 50px;
      bottom: 0;
      z-index: 10007;
      background-color: #3aaa35;
      background-color: var(--brand);
      border-bottom: 3px solid #ffdd00;
      border-bottom: 3px solid var(--playButton);
      padding-bottom: 17px;
      
      height: 60vh;
      width: 50vw;
    }

    .main-nav ul.main-menu {
      display: none;
    }
    .main-nav .sub__menus__arrows:hover .sub__menus {
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    .sub__menus__Active {
      display: block !important;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    .sub__menus__Active2 {
      display: block !important;
    }
    .menus__categorysss {
      display: none;
    }
    .menus__categorysss2 {
      display: none;
    }
    .menu-item .sub__menus__full a {
      text-align: left;
    }
    .ratingElement {
      width: 40px;
    }
    .action > * + * {
      margin-left: 15px;
    }
  }

@media (max-width: 550px) {
  .ratingElement {
    width: 30px;
  }
  .carousel .slide {
    padding: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(6% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(6% + 1px) !important;
  }
   
}

.selected {
  color: var(--brand) !important;
}

.navBarBTN {
}

.navBarBTN:hover {
  text-decoration: underline;
  cursor: pointer;
}
.categories_info {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.navBarSiteLogo {
  height: 75px;
  /* width: 75px; */
  width: auto;
  /* margin-top: -150px; */
  position: absolute;
  top:-85px
}

.navBarSiteLogo:hover {
  cursor: pointer;
}
.containerNavbar{
    background-color: var(--brand);
    margin-top: 60px;
}

.container {
  display: flex;
  flex-direction: column;
  /*background: linear-gradient(to right, #000000, #171d31, #000000);*/
  background: transparent;
  /*
  background-image: linear-gradient(to right, #000000, #171d31, #000000);
  background-image: -moz-linear-gradient(to right, #000000, #171d31, #000000);*/
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.item {
  transition: all 0.3s ease-in-out;
}

.chosenCategoryGrid {
  flex-grow: 1;
  height: 100%;
}

.categoryItemTitle {
  background-color: #646464;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.7rem 0.5rem 0.7rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  text-align: center;
  overflow: hidden;
  width: 15rem;
}

.categoryItemImg {
  object-fit: contain;
  display: block;
  max-height: 24rem;
  max-width: 16rem;
  /*min-height: 24rem;*/
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/*
.categoryItem:hover {
  transform: scale(1.1);
}
*/

.detailsUpper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 25%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}

.detailsUpperBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.detailsUpperSmall {
  width: 80%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.detailsUpperCover {
  width: 100%;
  vertical-align: middle;
}

.detailsPlayContainer {
  background-color: var(--brand);

  text-decoration: none;
  margin-bottom: 20px;
  color: var(--white);
  border: none;
  text-transform: uppercase;
  border-radius: var(--font12);
  line-height: 130%;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.detailsPlayContainer:hover .svg-play {
  fill: var(--brand);
}
.detailsPlayContainer:hover {
  cursor: pointer;
  background: var(--brandHover);
  color: var(--brand);
}

.svg-play {
  fill: white;
}

.detailsUpperCoverContainer {
  display: inline-block;
  width: 20%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.categoryContent,
.similiarCategoryContent {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.categoryContent::-webkit-scrollbar {
  display: none;
}

.banners {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.catArrow {
  color: white;
  width: 2rem;
  position: relative;
  z-index: 100;
  align-self: center;
  cursor: pointer;
  font-size: 3rem;
  transition: all 0.3s ease-in-out;
}

.catArrow:hover {
  background-color: rgba(20, 20, 20, 5);
  box-shadow: 0px 0px 1rem #fff;
  border-radius: 1rem;
}

.arrowRight {
  margin-left: auto;
  margin-right: 1rem;
}

.arrowLeft {
  margin-left: 1rem;
}

.categoryTitleContainer,
.similiarCategoryTitleContainer {
  margin: 1.5rem 0 1.5rem 0;
  color: #fcca13;
  font-family: 'Nunito', sans-serif;

  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.similiarContainer {
  margin-top: 1.5rem;
}

.categoryTitle {
  text-align: center;
  cursor: default;
}

.titleHover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.faqContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
}

.playVideoContainer {
  /*
 margin: 0 auto 0 auto;
  width: 100%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
  */

  overflow: hidden;
  /*
  position: relative;
  padding-top: 56.25%;
  */
}
.maxContainer{
  width: 70%;
  margin: 0 auto;
}

.maxContainerPrimary {
  width: 100%;
  background: black;
  position: relative;
  /* padding: 10px 0; */
}

/*
.radiantPlayer {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.radiantPlayer .rmp-control-bar {
  margin-top: -200px;
}
*/

.searchColumn {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
}

.searchButton {
  height: 50px;
}

.searchField {
  position: relative;
  padding: 17px 20px 17px 15px;
  outline: none;
  color: var(--background);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 500;
  max-height: 50px;
  min-height: 50px;
  width: 400px;
}

.searchRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  align-items: center;

  justify-content: space-between;
}

.searchCheckbox {
  margin-right: 0.25rem;
}

.searchCheckboxLabel {
  margin-right: 1%;
}

.resultsAmount {
  margin-left: 10%;
  margin-top: 1%;
  font-size: 16px;
  margin-right: 20%;
}

.searchResultBTN {
  background: var(--silver);
}

.active {
  background: var(--brand);
}
.searchResultBTN:hover {
  background: var(--brandHover);
}

.searchLabel {
  margin-bottom: 0.25rem;
  height: 18px;
}

.searchSelectCategory {
  padding: 0.25rem;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
  width: 80%;
  margin: 0 auto;
}

#searchForm {
  /* margin-left: 10%; */
}

.searchTitle {
  font-size: 28px;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.slick-track {
  display: flex !important;
  grid-gap: 10px;
  gap: 10px;
}

.videoContainer {
  padding-top: 5%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.details-description-container {
  width: 80%;
  margin: 0 auto;
  background-color: #1e2228;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
}

.detailsDescriptionTitleContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #fcca13;
  padding: 10px;
  overflow: hidden;
}

.details-description-title-left {
  display: flex;
  flex-direction: column;
  width: 70%;
  float: left;
}

.details-description-title-right {
  max-width: 30%;
  margin-left: auto;
}

.details-description-title-name {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: auto;
}

.folderNamesList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

.folderName {
  color: white;
  font-size: 20px;
  margin-right: 0.5rem;
}

.ratingElement {
  width: 50px;
}

.details-desription-mobile-cover {
  display: none;
}

.details-description-info-container {
  margin: 20px auto;
  display: flex;
}

.detailsContainer {
  font-family: 'Nunito', sans-serif;
}

.detailsInfoContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.infoDataContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--silver);
}

.dataTitle {
  font-weight: bold;
  margin-right: auto;
}

.dataValue {
  margin-right: auto;
  padding: 10px 0px;
}

.shareBTN {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.details-share {
  display: flex;
  padding-top: 35px;
  flex-wrap: wrap;
  width: 100%;
}

.detailsDescriptionContainer {
  flex: 3 1;
}

 

.svg-triangle {
  width: 26px;
  height: 20px;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.categoryGridContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.resultsGrid {
  width: 100%;
  margin-top: 1.5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.categoryItem {
  /* max-width: 17rem; */
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0.25rem;
}

.promoContainer {
  width: 99%;
  margin: 0 auto;
  overflow: hidden;
}

.promoItem {
  box-sizing: border-box;
  width: 33.333%;
  padding: 0.2rem;
}

.promoItemImg {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.bannerItem {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.categoryItem:hover {
  cursor: pointer;
}

.footerLine {
  border-top: 2px solid #f6c917;
  margin: 20px 0;
}

.bottomBar {
  width: 100%;
  height: 120px;
  padding: 0 10% 1% 10%;
  display: table;
  color: white;
  z-index: 5;
}

.footerInfo {
  padding-left: 20px;
  display: inline-block;
}

.footerInfoName {
  font-size: 18px;
  margin-bottom: 15px;
}

.footerInfoAddress {
  font-size: 12px;
  float: left;
  margin-right: 180px;
}

.footerInfoPhoneEmail {
  display: inline-block;
  font-size: 12px;
}

.footerTos {
  font-size: 12px;
  float: right;
  padding-right: 20px;
}

.footerTos:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tosContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  flex-grow: 1;
  color: white;
  font-size: 12px;
}

.SVOD-container,
.TVOD-container {
  margin: 0.5rem;
  width: 10rem;
  height: 10rem;
  background-color: #fcca13;
  text-align: center;
}

.SVOD-container:hover,
.TVOD-container:hover {
  cursor: pointer;
}

.main-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70.5%;
  justify-content: center;
}



.categoriesBar {
  display: flex;
  margin-top: 1rem;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.categoriesButtonContainer {
  display: inline-block;
  position: relative;
  color: white;
  font-size: 24px;
}

.categoriesButton {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.svg-chevron {
  margin-left: 0.5rem;
}

.dropdownOption {
  padding: 1rem;
  text-align: start;
  width: 100%;
  cursor: pointer;
  text-transform: capitalize;
}

.dropdownOption:hover {
  background: #313131;
}

.loginContainer,
.signUpContainer {
  cursor: pointer;
  display: flex;
}

.loginContainer > * + * {
  margin-left: 10px;
}
.modal-background {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  cursor: pointer;
  position: absolute;
  z-index: 110;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1.75rem auto;
  position: relative;
  background-color: #3d3d3d;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 2rem;
  max-width: 30rem;
  max-height: 20rem;
  z-index: 120;
  cursor: default;
}

#form_id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginFormInput {
  width: 100%;
  margin: 0.25rem 0 0.25rem 0;
}

.signUpBarInput {
  padding-left: 0.5rem;
  width: 100%;
  margin-right: 0.25rem;
}

.signUpBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
}

.signUpBar2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
  padding: 20px auto;
}

.signUpBackground {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
}

.signUpWelcomeText {
  font-size: 24px;
  width: 33.3%;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 48px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText3 {
  font-size: 28px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpRow {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  z-index: 2;
}

.loginMessage {
  height: 4rem;
}

.loginFormTOS {
  margin: 0.5rem 0 0.5rem 0;
  font-size: 18px;
  font-weight: normal;
}

.loginViewBackground {
  background-size: cover;
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  /*position: absolute;*/
  width: 100%;
  z-index: 1;
}

.loginViewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.loginFormButtons {
  display: flex;
  flex-direction: row;
}

.loginFormBTN-background {
  background: #3d3d3d;
}

.loginFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  margin-left: 10%;
}

.loginFormBTN:hover {
  background: transparent;
}
/*
.signUpBarBTN-background {
  background: #3d3d3d;
}
*/

.signUpBarBTN {
  background: rgb(125, 221, 14);
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0.5rem;
  font-weight: 300;
  border: none;
  font-size: 38px;
}

.signUpBarInfoMessage {
  background: #f6c917;
  display: flex;
  font-size: 18px;
  z-index: 5;
  color: black;
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.signUpBarBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpFormBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpForm {
  display: flex;
  flex-direction: column;
  width: 25%;
  color: white;
}

.stepsContainer {
  font-size: 20px;
}

.signUpFormInput {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.signUpStep1 {
  display: flex;
  flex-direction: column;
}

.signUpStepText {
  font-size: 20px;
  margin-bottom: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpStepTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.loginFormTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

#userDetails-form {
  display: flex;
  flex-direction: column;
}

.profile-container {
  width: 80%;
  flex-grow: 1;
  padding-top: 5%;
  margin: auto;
  display: flex;
  flex-direction: row;
  color: white;
}

.profile-menu-option {
  font-size: 18px;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.profile-menu-option:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(241, 241, 241, 0.2);
}

.profile-option-view {
  color: black;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.profile-details-row {
  display: flex;
  align-items: center;
  margin: 0.5rem auto 0.5rem auto;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.profileFormInput {
  width: 30rem;
  box-sizing: border-box;
}

.profileFormBTN {
  align-self: center;
  width: 5rem;
  margin-left: 10%;
}

.loginFirstMessage {
  font-size: 18px;
  margin: auto;
}

.profileMessage {
  margin: 2rem auto;
}

.series-seasons-container {
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.series-seasons-header {
  font-family: 'Nunito', sans-serif;

  color: white;
  padding: 20px 0;
}

.series-seasons-numbers {
  display: flex;
}
.series-seasons-numbers > a {
  margin-right: 5px;
}

.seasonNumber {
  background-color: var(--white);
  margin-right: 5px;
  color: var(--card);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.seasonNumber:hover {
  background-color: var(--brandHover);
}

.series-episode-list {
  width: 80%;
  margin: 0 auto;
}

.episodeDetailsMiddle {
  width: 67%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.episodeDetailsMiddleTop {
  display: flex;
  flex-direction: row;
}

.episodeDetailsMiddleBottom {
  margin-top: 1rem;
}

.episodeDetailsLeft {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 175px;
}

.episodeDetailsRight {
  display: flex;
  width: 11%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.episodeDetailsItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  background: var(--card);
  color: var(--silver);
  border-radius: 0.25rem;
  height: 120px;
  min-height: 100px;
  max-height: 100px;
}

.info-duration {
  margin-left: 20px;
}

.episode-list-img {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 100px;
  width: 175px;
}

.episode-list-img:hover {
  cursor: pointer;
}

.icon-play {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}
.icon-play:hover {
  color: var(--brandHover);
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}

.episode-list-description {
  color: white;
  font-family: 'Nunito', sans-serif;

  font-size: 16px;
}

.thumbnail-container {
  position: relative;
  box-sizing: border-box;
  height: 100px;
  width: 175px;
}

.informPlaceholder {
  font-size: 28px;
  color: white;
  text-align: center;
  height: 70%;
}

.play {
  /* background-image: url('../src/images/button_play_inactive.png'); */
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

 
.langFlag {
  margin-right: 0.5rem;
  width: 40px;
  max-width: 40px;
  cursor: pointer;
}

.languageSelect {
  margin-left: auto;
  padding-top: 10px;
}
.categoriesContainer {
  padding-bottom: 50px;
  /* width: 95%; */
  margin: 0 auto;
}
.bannerContainer {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 0px;
}
@media (max-width: 750px) {
  .bannerContainer {
    padding-bottom: 0px !important;
  }
  .categoriesContainer {
    max-width: 95%;
    margin: 0 5% 0 0;
  }
}


.loginContainer .active{

    background-color: var(--brandHover) !important;
    /* // height is same as navbar height */
    height: 50px;


}
.Footer_Footer__2EHIC {
  
   
  background-color: var(--brand);
}
.Footer_FooterPrimary__10Ujc {
  display: flex;
  width: 88%;
  margin: 0 auto;
  line-height: 20px;
  padding: 25px 0px 20px 0px;
}
.Footer_FooterPartner__n3n1U{
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 20px 30px;
    
}
.Footer_FooterPartner__n3n1U a{
    text-decoration: none;
}

.Footer_FooterPartnerWrapper__2Erul{
    display: flex;
}
.Footer_FooterPartnerWrapper__2Erul img{
/*    
   max-width: 100%;
   height: 100% !important; */
   width: 100%;
   height: 100%;


}
.Footer_FooterPartnerWrapper__2Erul a:hover{
  cursor: pointer;
       
    
    }

 .Footer_FooterOtherPartner__IzLGA .Footer_FooterPartnerWrapper__2Erul a,.Footer_FooterOtherPartner__IzLGA .Footer_FooterPartnerWrapper__2Erul div{
    margin:20px 26px ;

}
 .Footer_FooterMainPartner__3ndLL .Footer_FooterPartnerWrapper__2Erul a,.Footer_FooterMainPartner__3ndLL .Footer_FooterPartnerWrapper__2Erul div{
    margin:40px 26px ;
}
  .Footer_FooterPartnerWrapper__2Erul  {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px 0 10px;

}

.Footer_FooterOtherPartner__IzLGA,.Footer_FooterMainPartner__3ndLL{
    border-bottom: 1px solid #f0f0f0;
}

.Footer_FooterOtherLogo__2mpzm{
  padding:  43px 20px 80px;
}

.Footer_FooterOtherLogo__2mpzm img{
    height: 64px;
    width: 64px;
}
.Footer_FooterOtherLogo_LogoAndText__3WOpk,.Footer_FooterOtherLogo_Logo__3f8jJ{
    width: 33%;
}
.Footer_FooterOtherLogo_LogoAndText__3WOpk{
    display: flex;
   text-align: left;

}
.Footer_FooterOtherLogo_LogoAndText__3WOpk p{
    margin: 0 0 0 15px;
    color: #666;
    max-width: 154px;
    font-size: 12px;
    line-height: 16px;

}
.Footer_FooterOtherLogo_Logo__3f8jJ{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Footer_FooterPartner_img8__1MCdI{
    margin-top: 60px;
}
.Footer_Footer_Description_Heading__IbPPD,
.Footer_Footer_Menu_Heading__2kEmW,
.Footer_Footer_SocialMedia_Heading__qtTz- {
  height: 52px;
  letter-spacing: 0.5px;
  color: var(--playButton);
  font-weight: 500;
}

.Footer_Footer_Description_Heading__IbPPD{
  position: relative;

}
.Footer_Footer_Menu_Links__2Q0Pj{
  position: relative;
}
 
 
.Footer_Footer_Menu_Heading__2kEmW,
.Footer_Footer_SocialMedia_Heading__qtTz- {
  display: flex;
  align-items: center;
  font-weight: 800;
}
.Footer_Footer_Menu__3Rf7- a,
.Footer_Footer_SocialMedia__1mUIM a {
  color: var(--mainWhite);
  text-decoration: none;
}
.Footer_Footer_Menu__3Rf7- a:hover,
.Footer_Footer_SocialMedia__1mUIM a:hover {
  color: var(--playButton);
  text-decoration: none;
  transition: 0.1s ease;
}
.Footer_Footer_Menu__3Rf7-,
.Footer_Footer_SocialMedia__1mUIM {
  flex: 1 1;
}

.Footer_Footer_SocialMedia_Heading_Info__19gD5,
.Footer_Footer_Menu_info__2mwb- {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: var(--mainWhite);
  /* gap: 10px; */
  align-items: flex-start;
  justify-content: center;
}
.Footer_Footer_SocialMedia_Heading_Info__19gD5 > * + * {
  margin-top: 10px !important;
}
.Footer_Footer_Menu_info__2mwb- > * + * {
  margin-top: 10px !important;
}

.Footer_Footer_Description__2VUfG {
  color: var(--mainWhite);

  flex: 3 1;
}
.Footer_Footer_Description_Heading_Info__9HK1Y {
  padding: 10px 50px 10px 0;
}
.Footer_Footer_SocialMedia_Links__2vCPv {
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  color: var(--mainWhite);
}

.Footer_Footer_SocialMedia_Links__2vCPv > * + * {
  margin-left: 10px;
}
.Footer_Footer_SocialMedia_Links_icons__11lAS {
  color: var(--mainWhite);
}
/* media Queeries */
@media (max-width: 1200px) {
  .Footer_FooterPrimary__10Ujc {
    width: 95%;
  }
}
@media (max-width: 600px) {
  .Footer_FooterPrimary__10Ujc {
    flex-direction: column;
    width: 95%;
  }
  .Footer_Footer_Description_Heading__IbPPD{
    height: 0;
  }
  .Footer_Footer_Description_Heading_Info__9HK1Y {
    padding: 0 10px 0 0;
  }
  .Footer_Footer_SocialMedia_Heading_Info__19gD5,
  .Footer_Footer_Menu_info__2mwb- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 0px; */
  }
  .Footer_Footer_SocialMedia_Heading_Info__19gD5 > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .Footer_Footer_Menu_info__2mwb- > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .Footer_FooterOtherLogo_LogoAndText__3WOpk{
    width: 100%;
    margin-bottom: 40px;
    justify-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}


}

@media (max-width: 450px) {


.Footer_FooterPartner_img8__1MCdI{
    margin-top: 0px;
}

.Footer_FooterMainPartner__3ndLL .Footer_FooterPartnerWrapper__2Erul a{
    margin:30px 15px ;
}



}
.ItemImage_ViewedSectionPrimary__2S5EA {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ItemImage_ViewedSectionSecondary__2l7Ie {
  width: 80%;
  margin: auto;
}

.ItemImage_ViewedSectionSecondary__2l7Ie h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
}
.ItemImage_ViewedSectionSecondary__2l7Ie h2:hover {
  color: var(--mainYellow);
}
.ItemImage_ViewedSectionSecondary__2l7Ie h2:hover::after {
  background-color: var(--mainBlack);
}

.ItemImage_categoryList__RPohw {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ItemImage_ViewedSectionSecondary__2l7Ie .ItemImage_categoryList__RPohw ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ItemImage_categoryList__RPohw ul li {
  color: #999999;
  font-size: 0.75rem;
  padding-top: 12px;
}
.ItemImage_ViewedSectionSecondary__3videos__3pnOa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}

.ItemImage_ViewedSectionSecondary__3videos__image__1uuNW,
.ItemImage_ViewedSectionSecondary__2videos__image__2WCOd {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  border: 4px solid var(--brandPressed);
    border-radius: 16px;
}
.ItemImage_ViewedSectionSecondary__3videos__image__1uuNW img {
  width: 100%;
  height: 100%;
  /* border-radius: 16px; */
}

.ItemImage_ViewedSectionSecondary__3videos__image_overlay__19Gtt {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ItemImage_wrapper_bottom__3wyn4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ItemImage_wrapper_bottom_likes__29ehK,
.ItemImage_wrapper_bottom_duration__2Ty1e {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-size: 0.75rem;
  font-weight: 800;
}

.ItemImage_wrapper_bottom_likes__29ehK {
  margin-left: 10px;
}
.ItemImage_wrapper_bottom_duration__2Ty1e {
  margin-right: 10px;
}
.ItemImage_ViewedSectionSecondary__2videos__2TaP1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ItemImage_secondaryClassName__2clvk {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemImage_ViewedSectionSecondary__3videos__main__3icuV img,
.ItemImage_ViewedSectionSecondary__2videos__main__3bWY7 img {
  transition: 0.6s ease;
  width: 100%;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__AC55u {
  position: absolute;
  background: var(--cardbackground);
  color: var(--brand);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  border-radius: 16px;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay_blur__3Hrx6 {

  -webkit-backdrop-filter: blur(6px) drop-shadow(4px 4px 10px var(--playButton));

          backdrop-filter: blur(6px) drop-shadow(4px 4px 10px var(--playButton));
}

/* // animation of play button */
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__AC55u > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__AC55u:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__AC55u:hover {
  opacity: 1;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__28Qpp {
  cursor: pointer;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__28Qpp > * + * {
  margin-top: 20px;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__1G_yq {
  background: var(--brand);
  color: var(--brandHover);
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__1G_yq:hover {
  background: var(--brandHover);
  color: var(--brand);
}

.ItemImage_ViewedSectionSecondary__2videos__main__3bWY7 img {
  /* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.ItemImage_ViewedSectionSecondary__2videos__main__3bWY7:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__main__3bWY7:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__image__2WCOd:hover,
.ItemImage_ViewedSectionSecondary__3videos__image__1uuNW:hover {
  cursor: pointer;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__b5f9O {
  display: flex;
  flex-direction: column;

  grid-gap: 4px;

  gap: 4px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_heading__1SEN8 {
  font-weight: 500;
  color: var(--white);
  line-height: 20.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_duration__m8jIT {
  display: flex;
  font-weight: 400;
  color: var(--silver);
  grid-gap: 10px;
  gap: 10px;
  line-height: 16.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__b5f9O:hover {
  /* color: var(--mainYellow); */
  cursor: pointer;
}

.ItemImage_ViewedSectionSecondary__3videos__text_actions__1H2Q2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 0.875rem;
  color: #999999;
}
.ItemImage_ViewedSectionSecondary__3videos__text_actions__1H2Q2 p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.ItemImage_ViewedSectionSecondary__3videos__text_description_status__2kfN8 {
  color: var(--brand);
  line-height: 15.4px;
  font-weight: 400;
  font-size: var(--font11);
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_status__2kfN8:hover {
  color: var(--brandHover);
}

/* media Queeries */
@media (max-width: 750px) {
  .ItemImage_ViewedSectionSecondary__2l7Ie {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

.ItemTitle1_divider__ENZ-3 {
  margin-right: 5px;
  margin-left: 5px;
}

.ItemTitle1_ViewedSectionPrimary__309sy {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ItemTitle1_ViewedSectionSecondary__kph0f {
  width: 80%;
  margin: auto;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading_desc__3Ff1F {
  color: #ffffff;
  line-height: 140%;
}
.ItemTitle1_ViewedSectionSecondary__kph0f h2 {
  text-align: center;
  padding-top: 40px;
}
.ItemTitle1_ViewedSectionSecondary__kph0f h2:hover {
  color: var(--mainYellow);
}
.ItemTitle1_ViewedSectionSecondary__kph0f h2:hover::after {
  background-color: var(--mainBlack);
}

.ItemTitle1_categoryList__2wdI1 {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ItemTitle1_ViewedSectionSecondary__kph0f .ItemTitle1_categoryList__2wdI1 ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ItemTitle1_categoryList__2wdI1 ul li {
  color: #999999;
  padding-top: 12px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__3q6AI {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__2EtGw,
.ItemTitle1_ViewedSectionSecondary__2videos__image__6_Tm- {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__2EtGw img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* min-height: 190px; */
}
.ItemTitle1_ViewedSectionSecondary__3videos__image_overlay__3msLo {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ItemTitle1_wrapper_bottom__1JlrY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ItemTitle1_wrapper_bottom_likes__2CC_B,
.ItemTitle1_wrapper_bottom_duration__3aIIC {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-weight: 800;
}

.ItemTitle1_wrapper_bottom_likes__2CC_B {
  margin-left: 10px;
}
.ItemTitle1_wrapper_bottom_duration__3aIIC {
  margin-right: 10px;
}
.ItemTitle1_ViewedSectionSecondary__2videos__lR_HZ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ItemTitle1_secondaryClassName__2Jb1z {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemTitle1_ViewedSectionSecondary__3videos__main__1FW9S img,
.ItemTitle1_ViewedSectionSecondary__2videos__main__3jELA img {
  transition: 0.6s ease;
  width: 100%;
}
.ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__9SOkV {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__6_Tm-:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__9SOkV {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__2EtGw:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__9SOkV {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__3jELA img {
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__3jELA:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__3jELA:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__6_Tm-:hover,
.ItemTitle1_ViewedSectionSecondary__3videos__image__2EtGw:hover {
  cursor: pointer;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__2JAV5 {
  display: flex;
  flex-direction: column;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__2JAV5 > * + * {
  margin-top: 5px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__2l0iF {
  font-weight: 500;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__19vWU {
  display: flex;
  font-weight: 400;
  color: var(--brandHover);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__19vWU > * + * {
  margin-right: 5px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__2JAV5:hover {
  cursor: pointer;
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__39Vg4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__39Vg4 p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__2l0iF {
  /* color: var(--mainWhite); */
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3qA7i {
  color: var(--brand);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3qA7i:hover {
  /* color: var(--brandHover); */
}

/* media Queeries */
@media (max-width: 750px) {
  .ItemTitle1_ViewedSectionSecondary__kph0f {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

.ChosenCategoryGrid_chosenCategoryGrid__1CyYG {
  width: 80%;
  margin: 0 auto;
}

.ChosenCategoryGrid_ViewedSectionSecondary__3videos__tipuF {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

/* media Queeries */
@media (max-width: 950px) {
  .ChosenCategoryGrid_chosenCategoryGrid__1CyYG {
    width: 100%;
    margin: 0 10px;
  }
}
@media (max-width: 750px) {
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__tipuF {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 350px));
  }
}
@media (max-width: 550px) {
  .ChosenCategoryGrid_chosenCategoryGrid__1CyYG {
    width: 80%;
    margin: 0 auto;
  }
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__tipuF {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  }
}

.CategoryTitle2_CategoryItem_main__33rQj {
  /* margin: 30px auto; */
  margin-bottom: -30px;
  /* width: 90%; */
}
.CategoryTitle2_CategoryItem_main_info__1kPwd {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  width: 95%;
}

.CategoryTitle2_CategoryItem_main_link__3eB4x {
  height: 100%;
  border: none;
  color: var(--brand);
  text-decoration: none;
  font-weight: 400;
  line-height: 140%;
  transition: 0.3s ease;
}

.CategoryTitle2_CategoryItem_main_link__3eB4x:hover {
  cursor: pointer;
  color: var(--brandPressed);
}

.CategoryTitle2_CategoryItem_main_info_title__3SfsE {
  color: var(--mainBlack);
  line-height: 28px;
  margin: 0px 10px;
}

.CategoryTitle2_CategoryItem_main_info_title__3SfsE,
.CategoryTitle2_CategoryItem_main_info__1kPwd button {
  padding: 30px 0px;
}

.CategoryTitle2_CategoryItem_main_info_title__3SfsE {
  /* padding: 30px 0px; */
  padding: 0;
  background: var(--brand) !important;


min-width: 200px;
padding: 10px;
margin-bottom: 20px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-weight: 700;
line-height: 140%;
transition: 0.3s ease;

}



.ChannelsMainPage_ChannelsMainPage__TRNEO {
  padding-bottom: 30px;
  background-color: var(--mainWhite);

  color: black;
}
.ChannelsMainPage_ChannelsMainPage__main__1vx8V {
  margin: auto;
  width: 88%;
  /* padding-top: 40px; */
}
.ChannelsMainPage_ChannelsMainPage_Heading__3X2LJ {
  /* background-color: black; */
  color: var(--mainBlack);
  margin: 30px 0;
}
.ChannelsMainPage_ChannelsMainPage_primary__2DYBh {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%),300px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.ChannelsMainPage_ChannelsMainPage_secondary__1IX1x {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:20px 10px; */
  background-color: var(--card);
  height: 100%;
  flex-direction: column;
 /* margin-bottom: 20px; */
}
.ChannelsMainPage_ChannelsMainPage_secondary__1IX1x > * {
  flex: 1 1;
  height: 100%

  ;
}
.ChannelsMainPage_ChannelsMainPage_secondary__1IX1x > * + * {
  /* margin-top: 20px; */
  
}
.ChannelsMainPage_textDescription__2UCWu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
   
}
.ChannelsMainPage_textDescription__2UCWu > * + * {
  margin-top: 30px;
}

.ChannelsMainPage_channelName__2HU2S {
  /* font-size: 1rem; */
  color: var(--brandHover);
}
.ChannelsMainPage_channelName__2HU2S:hover {
  /* color: var(--brandHover); */
  /* cursor: pointer; */
}
.ChannelsMainPage_channeltext__13oDt {
  /* font-size: 1rem; */
  color: var(--mainBlack);
}

.ChannelsMainPage_channelButton__33dDu {
  padding: 15px 40px;
  background-color: var(--brandHover);
  color: var(--mainWhite);
  border: none;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* font-size: 15px; */
}
.ChannelsMainPage_channelButton__33dDu:hover {
  background-color: var(--brand);
  transition: 0.5s ease all;
  cursor: pointer;
}
.ChannelsMainPage_channelsLink__3SMzG {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 60px;
  gap: 60px;
  padding: 70px 0;
}
.ChannelsMainPage_channelsLink__3SMzG a {
  padding: 10px 25px;
  /* font-size: 1.25rem; */
  cursor: pointer;
  text-decoration: none;
  /* font-weight: 500; */
  color: var(--mainBlack);

  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 0;
}

.ChannelsMainPage_channelsLink__3SMzG a:hover,
.ChannelsMainPage_channelsLink__3SMzG a.ChannelsMainPage_active__AjF5Y,
.ChannelsMainPage_channelsLink__3SMzG a:active {
  background-color: transparent;
  border-left: 4px solid var(--brandHover);
  border-radius: none;
  color: var(--brandHover);
  outline: none;
}
.ChannelsMainPage_channel__2Bisl{
  display: flex;
  flex-direction: column;
}
.ViewedSection_ViewedSectionPrimary__1IHFo {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
/* .ViewedSectionSecondary {
  width: 80%;
  margin: auto;
} */

.ViewedSection_ViewedSectionSecondary__35Qvs h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
  color: var(--mainBlack);
}
.ViewedSection_ViewedSectionSecondary__35Qvs h2:hover {
}
.ViewedSection_ViewedSectionSecondary__35Qvs h2::after {
  background-color: var(--mainBlack);
}

.ViewedSection_categoryList__1CaNk {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ViewedSection_ViewedSectionSecondary__35Qvs .ViewedSection_categoryList__1CaNk ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ViewedSection_categoryList__1CaNk ul li {
  color: #999999;
  padding-top: 12px;
}
.ViewedSection_ViewedSectionSecondary__3videos__3sOez {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.ViewedSection_ViewedSectionSecondary__3videos__image__23-UP,
.ViewedSection_ViewedSectionSecondary__2videos__image__1lE2b {
  /* margin-bottom: 20px; */
  position: relative;
  overflow: hidden;
  /* height: 200px;
  min-height: 200px;
  max-height: 200px; */
}
.ViewedSection_ViewedSectionSecondary__3videos__image__23-UP img {
  width: 100%;
  height: 100%;
  /* min-height: 190px; */
}
.ViewedSection_ViewedSectionSecondary__3videos__image_overlay__2IphU {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ViewedSection_wrapper_bottom__1-9FL {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ViewedSection_wrapper_bottom_likes__1HC1B,
.ViewedSection_wrapper_bottom_duration__d21nN {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.ViewedSection_wrapper_bottom_likes__1HC1B {
  margin-left: 10px;
}
.ViewedSection_wrapper_bottom_duration__d21nN {
  margin-right: 10px;
}

.ViewedSection_wrapper_bottom_likes__1HC1B > * + * {
  margin-right: 10px;
}
.ViewedSection_ViewedSectionSecondary__2videos__1nTXE {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ViewedSection_secondaryClassName__3sgG1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ViewedSection_ViewedSectionSecondary__3videos__main__2CJUP img,
.ViewedSection_ViewedSectionSecondary__2videos__main__57E8c img {
  transition: 0.6s ease;
  width: 100%;
}
.ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__3DRvt {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.699); */
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ViewedSection_ViewedSectionSecondary__2videos__image__1lE2b:hover
  .ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__3DRvt {
  opacity: 1;
}
.ViewedSection_ViewedSectionSecondary__3videos__image__23-UP:hover
  .ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__3DRvt {
  opacity: 1;
}
.ViewedSection_ViewedSectionSecondary__2videos__main__57E8c img {
  /* height: 350px;
  min-height: 350px;
  max-height: 350px; */
}
.ViewedSection_ViewedSectionSecondary__2videos__main__57E8c:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ViewedSection_ViewedSectionSecondary__2videos__main__57E8c:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ViewedSection_ViewedSectionSecondary__2videos__image__1lE2b:hover,
.ViewedSection_ViewedSectionSecondary__3videos__image__23-UP:hover {
  cursor: pointer;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_description__1JN6K {
  text-align: center;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_description__1JN6K:hover {
  color: var(--mainYellow);
  cursor: pointer;
}

.ViewedSection_ViewedSectionSecondary__3videos__text_actions__1rBa8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_actions__1rBa8 p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

/* media Queeries */
@media (max-width: 750px) {
  /* .ViewedSectionSecondary {
    width: 100%;
    margin: 0 10px 0 10px;
  } */
}
@media (max-width: 550px) {
}

.ChannelVideos_SecondaryContainer__HZOgP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 50px;
  row-gap: 50px;
  color: var(--mainBlack);
}
.ChannelVideos_PrimaryContainer__2PaUj {
  width: 80%;
  margin: 0 auto;
}
.ChannelVideos_NoDataAvailable__3297Z {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.ChannelVideos_NoDataAvailable__3297Z h2 {
  color: var(--mainBlack);
  text-align: start;
}

.ChannelVideos_NoDataAvailable__3297Z h2::after {
  display: none;
}

.ChannelVideos_NoDataAvailable__3297Z img {
  width: 400px;
}
 
@media (max-width: 850px) {
  .ChannelVideos_NoDataAvailable__3297Z img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .ChannelVideos_NoDataAvailable__3297Z img {
    width: 200px;
  }
}



.Header_HeaderTitle__1BnSO {
    height: 100%;
    border: none;

    text-decoration: none;
    font-weight: 700;
    line-height: 140%;
    transition: 0.3s ease;

    background-color: var(--brand) !important;

    min-width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mainWhite);
}

.Header_HeaderTitleAdmin__YENO5 {
    background-color: var(--mainWhite) !important;
    color: var(--mainBlack) !important;
    padding: 10px 0 ;
    justify-content: flex-start;
}
.DetaillsFolderNames_folderName__2RRy4 {
  margin-right: 0.5rem;
  background: var(--darkGray);
  color: var(--blueSilver);
  border-radius: 5px;
  padding: 10px 10px;

  border-radius: 100px;
}
.DetaillsFolderNames_folderName__2RRy4:hover {
  background: var(--brandHover);
  cursor: pointer;
  color: var(--brand);
}

.RenderItemDescription_itemDescription__5DHqo {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /* padding: 0 15% 0 1%; */
  color: var(--silver);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22.4px;
  line-height: 1.62857;
}

@media (max-width: 550px) {
  .RenderItemDescription_itemDescription__5DHqo {
    line-height: 19.6px;
    line-height: 1.62857;
  }
}

.ContactUs_ContactUs__1FlPn {
    width: 88%;
    margin: 0 auto;
  }
  .ContactUs_ContactUsPrimary__3ch1R {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-evenly;
    align-items: center;
  }
  .ContactUs_ContactUsPrimary__3ch1R > * + * {
    margin-left: 50px;
  }
  .ContactUs_ContactUs_image__2Wsv0 {
    flex: 0.8 1;
  
    display: flex;
  }
  .ContactUs_ContactUs_image__2Wsv0 img {
    height: 100%;
    width: 100%;
  }
  .ContactUs_ContactUs_description__1XmNv {
    flex: 1 1;
    color: var(--mainBlack);
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-direction: column;
  }
  .ContactUs_ContactUs_description_Primary__2Et33 {
    width: 80%;
    margin: 0 auto;
  }
  .ContactUs_ContactUs_description__1XmNv h4 {
    padding: 0 60px;
    color: var(--brand);
  }
  .ContactUs_ContactUs_description_Primary__2Et33 > * + * {
    margin-top: 50px;
  }
  
  .ContactUs_ContactUs_description_email_button__1L-mu {
    background-color: var(--brand);
    text-decoration: none;
    color: var(--white);
    padding: 10px 56px;
    border: none;
    font-size: var(--font16);
    border-radius: 12px;
  }
  .ContactUs_ContactUs_description_email_button__1L-mu:hover {
    background-color: var(--brandHover);
    color: var(--brand) !important;
  }
  .ContactUs_ContactUs_description_address_info__15qoG,
  .ContactUs_ContactUs_description_phone__2DT0r,
  .ContactUs_ContactUs_description_email__jtsUm {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 60px;
  }
  .ContactUs_ContactUs_description_address_info__15qoG > * + * {
    margin-top: 5px;
  }
  .ContactUs_ContactUs_description_address_info_title__2EuLi {
    margin-bottom: 10px !important;
    color: var(--brandHover);
  }
  /* media Queeries */
  @media (max-width: 991px) {
    .ContactUs_ContactUs_image__2Wsv0 {
      display: none;
    }
  }
  
  @media (max-width: 550px) {
    .ContactUs_ContactUs__1FlPn {
        width: 100%;
      }
  }
  

.Pricing_pricing__17_Cu{
    display: flex;
}
.Pricing_pricing__17_Cu img{
    max-width: 650px;
    margin: 0 auto;
    width: 650px;

    justify-content: center;

 
}

@media (max-width:650px) {
    .Pricing_pricing__17_Cu img{
        width: 100%;
    }
    
}
.HeroBanner_primarycontainer__1BJzM {
  background-color: hsla(0, 2%, 9%, 0.64);
}

.HeroBanner_secondaryContainer__17Js9 {
  /* padding: 0px 20px; */
  width: 100vw;
  margin: auto;
  color: var(--mainWhite);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  gap: 10px;
}

.HeroBanner_container_singleItemPrimary__2C1C0 {
  height: 70vh;
  min-height: 70vh;
  max-height: 70vh;
  position: relative;
}
.HeroBanner_container_singleItemPrimary__2C1C0 img {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.HeroBanner_container_singleItem__3D85n {
  position: relative;
}

.HeroBanner_container_singleItem__3D85n:hover .HeroBanner_wrapper_center__3_x_S button {
  transition: 0.5s ease;
  background-color: var(--mainYellow);
}
.HeroBanner_container_singleItem__3D85n:hover {
  cursor: pointer;
}

.HeroBanner_wrapper_center__3_x_S {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 50px;
  gap: 50px;
  margin-top: 25vh;
}

.HeroBanner_wrapper_center__3_x_S h3 {
  font-size: 4.286rem;
}
.HeroBanner_wrapper_center__3_x_S button {
  padding: 15px 30px;
  background-color: black;
  color: var(--mainWhite);
  border: none;
  text-transform: uppercase;
  border-radius: 0;
  /* font-size: inherit; */
}
/* .wrapper_center button:hover {
} */
.HeroBanner_wrapper_bottom__qnZ8i {
  position: absolute;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 800;
}

.HeroBanner_wrapper_bottom_likes__2e-YZ {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-left: 10px;
}
.HeroBanner_wrapper_bottom_duration__PIkFO {
  margin-right: 10px;
}
.HeroBanner_posterImage__2Fswb {
  height: 80px;
}
.HeroBanner_container_singleItem_image__3X9Ee {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.HeroBanner_container_multipleItem__BZTtJ {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 250px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  /* justify-content: space-between; */
}

.HeroBanner_container_multipleItemprimary__2T6Km {
  /* overflow: hidden; */
  position: relative;
  min-width: 250px;
  min-height: 250px;
}
.HeroBanner_container_multipleItemprimary__2T6Km:hover {
  cursor: pointer;
}
.HeroBanner_container_multipleItem__text__2rQdG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px 0;
}
.HeroBanner_container_multipleItem__text__2rQdG .HeroBanner_post_cateogory__19gVt {
  color: var(--mainYellow);
  font-size: 0.875rem;
}
.HeroBanner_container_multipleItem__text__2rQdG .HeroBanner_post_title__213Uq {
  font-size: 1rem;
}

.HeroBanner_container_multipleItem__image__1kmKC {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.HeroBanner_post_title__213Uq:hover {
  color: var(--mainYellow);
  transition: 0.3s ease;
}

.HeroBanner_container_multipleItem__image__1kmKC img {
  width: 100%;
}

.HeroBanner_editorChoice__3yRRP {
  top: 0;
  position: absolute;
}
.HeroBanner_editorChoice__3yRRP img {
  width: 40px;
}
@media (max-width: 1300px) {
  .HeroBanner_container_multipleItem__BZTtJ {
    justify-content: center;
  }
}
/* media Queeries */
@media (max-width: 750px) {
  .HeroBanner_secondaryContainer__17Js9 {
    width: 100vw;
    margin: 0 10px 0 10px;
  }
  .HeroBanner_posterImage__2Fswb {
    height: 100px;
  }
  .HeroBanner_wrapper_center__3_x_S h3 {
    font-size: 3.286rem;
  }

  .HeroBanner_editorChoice__3yRRP img {
    width: 30px;
  }
}
@media (max-width: 550px) {
  .HeroBanner_container_multipleItem__BZTtJ {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    align-items: center;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: center;
  }
  .HeroBanner_editorChoice__3yRRP img {
    width: 20px;
  }

  .HeroBanner_secondaryContainer__17Js9 {
    /* width: 90vw; */
  }
  .HeroBanner_wrapper_center__3_x_S h3 {
    font-size: 2.286rem;
  }
  .HeroBanner_posterImage__2Fswb {
    height: 70px;
  }
}
.HeroBanner_bannerTitle_title__KstSO {
  position: absolute;
  bottom: 50px;
  padding: 50;
  margin-bottom: 0;
  background: var(--mainWhite);
  color: var(--mainBlack);
  padding: 10px 60px;
  font-weight: 800;
}
.HeroBanner_bannerTitle__1WUTq {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  background: var(--mainWhite);
}

@media (max-height: 850px) {
  .HeroBanner_wrapper_center__3_x_S {
    margin-top: 10vh;
  }
}
@media (max-height: 700px) {
  .HeroBanner_wrapper_center__3_x_S {
    grid-gap: 20px;
    gap: 20px;
    margin-top: 5vh;
  }
}
@media (max-height: 550px) {
  .HeroBanner_wrapper_center__3_x_S {
    grid-gap: 0px;
    gap: 0px;
    margin-top: 5vh;
  }
}

.Events_container_2videos__2Y8jS {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
 

 

 
 
.Events_Events_main_info__1NAUE {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}



.Events_Events_main_info__1NAUE h2 {
  padding: 10px 30px;
}

.Events_Events_main__3tUC7 h2::after {
  display: none;
}
.Events_container_3videos__1BBOd {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
.Events_container_homePage__2A2EJ {
  width: 95%;
}
.Events_EventTitle__34YXs {
  color: var(--brandPressed);
  font-weight: 500;
  /* font-family: 'Russo One', sans-serif; */
}

.Events_Events__1nxLG {
  background-color: var(--card );
  border-radius: 10px;
}
.Events_Events__1nxLG:hover {
  cursor: pointer;
}
.Events_Event__xjEqG {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--brand);
  padding-bottom: 20px;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: var(--mainWhite);
}
.Events_EventTitle__34YXs {
  padding-bottom: 10px;
}

.Events_EventInfo__1kPhd {
  color: var(--mainYellow);
  padding-bottom: 20px;
  /* font-weight: 700; */
}
.Events_EventDuration__2tCGp {
  margin-top: 10px;
  /* font-weight: 700; */
}
.Events_EventInfo__1kPhd,
.Events_EventTitle__34YXs,
.Events_EventDuration__2tCGp {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.Events_EventInfo__1kPhd > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__34YXs > * + * {
  margin-left: 10px !important;
}
.Events_EventDuration__2tCGp > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__34YXs:hover {
  text-decoration: underline;
  color: var(--brand);
}
.Events_icon__1s6tT {
}

.Events_event__23KCg {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.Events_event__23KCg h2 {
  color: var(--mainWhite);
  padding-bottom: 0;
}
.Events_iframe__1TZwF {
  /* height: 80vh; */
  border: none;
  margin: 10px 0;
}

.Events_ViewedSectionSecondary__2videos__lnS0X {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.Events_ViewedSectionSecondary__3videos__2xbZJ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(275px, 100%), 250px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  
}
.Events_NoDataAvailable__1dHK5 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 0 30px 0;
}

.Events_NoDataAvailable__1dHK5 h3 {
  color: var(--brand);
}
.Events_NoDataAvailable__1dHK5 img {
  width: 400px;
}

.Events_modalItem__1nMcx {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--mainWhite);
}
.Events_modalItem_button__37kZR {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}
.Events_modalItem_button__37kZR button {
}
.Events_modalItem_button_confirm__3llPa {
  background-color: var(--brand);
  color: var(--mainWhite);
}
.Events_modalItem_button_confirm__3llPa:hover {
  background-color: var(--brandHover);
  color: var(--mainWhite);
}
.Events_modalItem_button_cancel__37g0f:hover {
  background-color: var(--brandPressed);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__3O7rt,
.Events_Events_main_info_filter_removeFilter__38LQ5 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Events_Events_main_info_filter_addFilter__3O7rt {
  background-color: var(--brand);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__3O7rt:hover .Events_filterIcon__2MZY5 {
  background-color: var(--playButton);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__3O7rt:hover {
  background-color: var(--brandHover);

  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__38LQ5:hover .Events_removeIcon__1Vkqu {
  background-color: var(--brandPressed);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__38LQ5:hover {
  background-color: var(--brandPressed);
  color: var(--mainWhite);
}
/* filter icon */
.Events_filterIcon__2MZY5 {
  color: var(--mainYellow);
}
.Events_filterIcon__2MZY5:hover {
  color: var(--mainWhite);
  cursor: pointer;
}
.Events_removeIcon__1Vkqu {
  color: var(--brandPressed);
}

.Events_Events_main_info_button__ebPtI {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Events_Events_main_info_header__AWsuZ {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1050px) {
  .Events_container_2videos__2Y8jS {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .Events_ViewedSectionSecondary__2videos__lnS0X {
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
  .Events_container_2videos__2Y8jS {
    width: 100%;
  } 
  .Events_Events_main_info__1NAUE h2 {
    padding: 20px 10px;
  }
  .Events_NoDataAvailable__1dHK5 img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .Events_container_2videos__2Y8jS {
    width: 88%;
    margin: 0 auto;
  }
  .Events_NoDataAvailable__1dHK5 img {
    width: 200px;
  }
  .Events_ViewedSectionSecondary__3videos__2xbZJ {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 250px));
    
  }
}
@media (max-width: 550px) {
  
  .Events_ViewedSectionSecondary__3videos__2xbZJ {
    justify-content: center;

    
  }
}

.EventsItem_ViewedSectionPrimary__dfTIJ {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
/* .ViewedSectionSecondary {
    width: 80%;
    margin: auto;
  } */

.EventsItem_ViewedSectionSecondary__1dxwu h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
  color: var(--mainBlack);
}
.EventsItem_ViewedSectionSecondary__1dxwu h2:hover {
}
.EventsItem_ViewedSectionSecondary__1dxwu h2::after {
  background-color: var(--mainBlack);
}

.EventsItem_categoryList__3zcMH {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.EventsItem_ViewedSectionSecondary__1dxwu .EventsItem_categoryList__3zcMH ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.EventsItem_categoryList__3zcMH ul li {
  color: #999999;
  padding-top: 12px;
}
.EventsItem_ViewedSectionSecondary__3videos__1feEa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.EventsItem_ViewedSectionSecondary__3videos__image__vsm8L,
.EventsItem_ViewedSectionSecondary__2videos__image__qFSt1 {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}
.EventsItem_ViewedSectionSecondary__3videos__image__vsm8L img {
  width: 100%;
  height: 100%;
  /* min-height: 190px; */
}
.EventsItem_ViewedSectionSecondary__3videos__image_overlay__2bk7r {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.EventsItem_wrapper_bottom__3l0Si {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.EventsItem_wrapper_bottom_likes__CbTnG,
.EventsItem_wrapper_bottom_duration__-WJOB {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.EventsItem_wrapper_bottom_likes__CbTnG {
  margin-left: 10px;
}
.EventsItem_wrapper_bottom_duration__-WJOB {
  margin-right: 10px;
}

.EventsItem_wrapper_bottom_likes__CbTnG > * + * {
  margin-right: 10px;
}
.EventsItem_ViewedSectionSecondary__2videos__1QcXb {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.EventsItem_secondaryClassName__1ah6C {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.EventsItem_ViewedSectionSecondary__3videos__main__2Et3L img,
.EventsItem_ViewedSectionSecondary__2videos__main__1seoq img {
  transition: 0.6s ease;
  width: 100%;
}
.EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__3Ke_Z {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.699); */
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.EventsItem_ViewedSectionSecondary__2videos__image__qFSt1:hover
  .EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__3Ke_Z {
  opacity: 1;
}
.EventsItem_ViewedSectionSecondary__3videos__image__vsm8L:hover
  .EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__3Ke_Z {
  opacity: 1;
}
.EventsItem_ViewedSectionSecondary__2videos__main__1seoq img {
  /* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.EventsItem_ViewedSectionSecondary__2videos__main__1seoq:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.EventsItem_ViewedSectionSecondary__2videos__main__1seoq:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.EventsItem_ViewedSectionSecondary__2videos__image__qFSt1:hover,
.EventsItem_ViewedSectionSecondary__3videos__image__vsm8L:hover {
  cursor: pointer;
}
.EventsItem_ViewedSectionSecondary__3videos__text_description__3ZLEN {
  text-align: center;
}
.EventsItem_ViewedSectionSecondary__3videos__text_description__3ZLEN:hover {
  color: var(--mainYellow);
  cursor: pointer;
}

.EventsItem_ViewedSectionSecondary__3videos__text_actions__3_2ab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.EventsItem_ViewedSectionSecondary__3videos__text_actions__3_2ab p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.EventsItem_Events_Image__gf53Y img {
  height: 150px;
  max-height: 150px;
  min-height: 150px;
}

/* media Queeries */
@media (max-width: 750px) {
  /* .ViewedSectionSecondary {
      width: 100%;
      margin: 0 10px 0 10px;
    } */
}
@media (max-width: 550px) {
}

 
 /* link which is inside ItemLink */
.ItemLink_ItemLink__2TzVe a {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
  }
  .ItemLink_ItemLink__2TzVe a:hover {
    transition: all ease 1s;
    cursor: pointer;
    color: var(--brandPressed);
  
  }
 /* which does not include a href */


  .ItemLink_ItemLink__2TzVe  {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    font-weight: 800;
  }
  .ItemLink_ItemLink__2TzVe:hover {
    transition: all ease 1s;
    cursor: pointer;
    color: var(--brandPressed);
  
  }
.EditorChoice_EditorChoice__1CR6n {
  /* background: url('../../images/backgroundImageEditorChoice.png'); */
  background-position: center !important  ;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-top: 15px !important;
  padding-bottom: 110px !important;
  width: 100%;
  /* height: 100%; */
  color: white;
}

.EditorChoice_EditorChoicePrimary__16Vqd {
  width: 90%;
  margin: auto;
}
.EditorChoice_EditorChoice__1CR6n h2 {
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}
.EditorChoice_starIcon__v1DN_ {
  padding-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: var(--white);
}

/* media Queeries */
@media (max-width: 750px) {
  .EditorChoice_EditorChoicePrimary__16Vqd {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

 
.ChannelEvents_ChannelEventsPrimaryContainer__ZPa6L{
  width: 80%;
  margin: 0 auto;

}
.RenderBanner_primarycontainer__1d5Zc {
  background-color: hsla(0, 2%, 9%, 0.64);
}
.RenderBanner_primarycontainer_Promo__FIgwL {
  padding: 10px 0 50px 0;
}
.RenderBanner_secondaryContainer__2lL-3 {
  width: 80vw;
  margin: auto;
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  gap: 10px;
}
/* // aspect-ratio  */

.RenderBanner_container_singleItemPrimaryBanner1__1TeKz {
  position: relative;
  padding-bottom: 56.25%;
}
.RenderBanner_container_singleItemPrimaryBanner2__3R_ob {
  position: relative;
}
.RenderBanner_container_singleItem_image__2CjHd {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
}

.RenderBanner_container_singleItem__MRlo_ {
  position: relative;
  color: var(--white);
  cursor: pointer;
}

.RenderBanner_wrapper_center__86uqX {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 12%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  /* width: 100%; */
  padding: 30px 10px;
}

.RenderBanner_wrapper_center_info__sGWsr {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  font-weight: 500;
  align-items: flex-start;
  color: var(--red);
}
.RenderBanner_wrapper_center_info_category__VJ5qb,
.RenderBanner_wrapper_center_info_duration__2UJ0f {
  opacity: 0.9;
  grid-gap: 3px;
  gap: 3px;
  display: flex;
}

.RenderBanner_wrapper_center_info_category__VJ5qb {
}
.RenderBanner_wrapper_center_info_title__F9A3m {
  font-size: var(--font36);
  font-weight: 800;
  opacity: 1 !important;
  margin-bottom: 20px;
}
.RenderBanner_wrapper_center__86uqX button {
  background-color: var(--brand);
  color: var(--white);
  border: none;
  text-transform: uppercase;
  border-radius: var(--font12);
  line-height: 130%;

  /* font-size: inherit; */
}
.RenderBanner_wrapper_center__86uqX button:hover {
  cursor: pointer;
  background-color: var(--brandHover);
  /* color: var(--brand); */
  transition: 0.3s ease;
}
.RenderBanner_wrapper_center__86uqX button:focus {
  cursor: pointer;
  color: var(--brandPressed);
  transition: 0.3s ease;
}
/* .wrapper_center button:hover {
  } */
.RenderBanner_wrapper_bottom__wR9xI {
  position: absolute;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 800;
}

.RenderBanner_wrapper_bottom_likes__Tcuhb {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-left: 10px;
}
.RenderBanner_wrapper_bottom_duration__3DhAQ {
  margin-right: 10px;
}
.RenderBanner_posterImage__zYa7y {
  height: 80px;
}

.RenderBanner_container_multipleItem__3gUeM {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.RenderBanner_container_multipleItemprimary__utR9J {
  /* overflow: hidden; */
  position: relative;
  min-width: 250px;
  min-height: 250px;
}
.RenderBanner_container_multipleItemprimary__utR9J:hover {
  cursor: pointer;
}
.RenderBanner_container_multipleItem__text__3SWf6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px 0;
}
.RenderBanner_container_multipleItem__text__3SWf6 .RenderBanner_post_cateogory__2m_Pt {
  color: var(--mainYellow);
}
.RenderBanner_container_multipleItem__text__3SWf6 .RenderBanner_post_title__1rgD3 {
}

.RenderBanner_container_multipleItem__image__3HNi3 {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.RenderBanner_post_title__1rgD3:hover {
  color: var(--mainYellow);
  transition: 0.3s ease;
}

.RenderBanner_container_multipleItem__image__3HNi3 img {
  width: 100%;
}

.RenderBanner_editorChoice__3-0_G {
  position: absolute;
  top: 0;
}
.RenderBanner_editorChoice__3-0_G img {
  width: 40px;
}
.RenderBanner_bannerTitle_title__1RnRH {
  position: absolute;
  bottom: 50px;
  padding: 50;
  margin-bottom: 0;
  background: var(--mainWhite);
  color: var(--brandHover);
  padding: 10px 60px;
  /* font-weight: 800; */
}
.RenderBanner_bannerTitle__1EEyj {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  background: var(--mainWhite);
}

/* media Queeries */
@media (max-width: 750px) {
  .RenderBanner_secondaryContainer__2lL-3 {
    width: 100vw;
    margin: 0 10px 0 10px;
  }
  .RenderBanner_posterImage__zYa7y {
    height: 100px;
  }
  .RenderBanner_wrapper_center_info_title__F9A3m {
  }

  .RenderBanner_editorChoice__3-0_G img {
    width: 30px;
  }
  .RenderBanner_wrapper_center__86uqX {
    left: 10%;

    padding: 20px 0px;
  }
}
@media (max-width: 650px) {
  .RenderBanner_wrapper_center__86uqX button {
    display: none;
  }

  /* font-size: inherit; */
  .RenderBanner_wrapper_center__86uqX {
    top: 40%;

    padding: 20px 0px;
  }

  .RenderBanner_wrapper_center_info__sGWsr {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .RenderBanner_container_multipleItem__3gUeM {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    align-items: center;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .RenderBanner_editorChoice__3-0_G img {
    width: 20px;
  }

  .RenderBanner_secondaryContainer__2lL-3 {
    /* width: 90vw; */
  }
  .RenderBanner_wrapper_center__86uqX {
    left: 5%;
  }

  .RenderBanner_posterImage__zYa7y {
    height: 70px;
  }
  .RenderBanner_bannerTitle_title__1RnRH {
   padding: 0;
  }
}

/* Height */

@media (max-height: 850px) {
  .RenderBanner_wrapper_center__86uqX {
    margin-top: 0vh;
  }
}
@media (max-height: 700px) {
  .RenderBanner_wrapper_center__86uqX {
    grid-gap: 20px;
    gap: 20px;
  }
}

@media (max-height: 550px) {
  .RenderBanner_wrapper_center__86uqX {
    grid-gap: 0px;
    gap: 0px;
  }
}

.RadiantPlayer_RadiantPlayer__3X3GN {
    /* position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1000; */
    width: 100%;
    /* padding: 20px 0; */
    /* margin: 0 auto; */
  }
  .RadiantPlayer_RadiantPlayer__3X3GN .RadiantPlayer_rmp-control-bar__2XT_3 {
    /* margin-top: -200px; */
  }
  

.UpcomingEvent_EventPrimary__1Annn {
  width: 100%;
  margin: 30px auto 0 auto;
  background-color: var(--white);
  color: var(--silver);
  /* margin-top: 50px; */
  padding: 30px;
}
.UpcomingEvent_EventSecondary__pF7no {
  width: 88%;
  margin: 0 auto;
}
.UpcomingEvent_SecondaryContainer__1mQ3W {
  background-color: black;
  width: 88%;
  margin: 0 auto;
  padding: 20px 10px;
}
.UpcomingEvent_EventTertairy__3wzWV {
  width: 88%;

  margin: 0 auto;
}
.UpcomingEvent_EventPrimary__1Annn > * {
  padding-bottom: 10px;
}

.UpcomingEvent_EventPrimary_title__2cKmt {
  padding: 50px 0 20px 0;
  color: var(--itemTitle);
}
h1::after,
h2::after {
  display: none;
}
.UpcomingEvent_EventPrimary_countdown__1l76H {
  /* font-size: 2.5rem; */
}
.UpcomingEvent_EventPrimary_countdown__1l76H {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.UpcomingEvent_EventPrimary_countdown__1l76H > * + * {
  margin-left: 20px;
}

.UpcomingEvent_EventPrimary_countdown__1l76H span {
  padding: 20px;
  background-color: var(--brand);
  color: var(--mainWhite);
}
.UpcomingEvent_EventPrimary_countdown_description__1C26o {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UpcomingEvent_EventPrimary_countdown_description__1C26o > * + * {
  margin-top: 10px;
}

.UpcomingEvent_EventPrimary_countdown_description__1C26o p,
.UpcomingEvent_EventPrimary_date__2ZcSq,
.UpcomingEvent_EventPrimary_time__1JKvT {
  /* font-size: 1.3rem; */
}

.UpcomingEvent_EventPrimary_date__2ZcSq,
.UpcomingEvent_EventPrimary_time__1JKvT {
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
}
.UpcomingEvent_EventPrimary_date__2ZcSq > * + * {
  margin-left: 20px;
}
.UpcomingEvent_EventPrimary_time__1JKvT > * + * {
  margin-left: 20px;
}
.UpcomingEvent_EventTimeDescription__2_aHi > * + * {
  margin-top: 20px;
}
.UpcomingEvent_EventTimeDescription__2_aHi {
  margin-top: 60px;
  margin-bottom: 50px;
  color: var(--brand);
}

.UpcomingEvent_EventPrimary_description__1Tind > * {
  margin: 30px 0;
  line-height: 200%;
}
.UpcomingEvent_EventPrimary_description__1Tind span,
.UpcomingEvent_EventPrimary_description__1Tind p,
.UpcomingEvent_EventPrimary_description__1Tind h1 h2 h3 {
  background-color: white !important;
  color: var(--mainBlack) !important;
}
.UpcomingEvent_EventPrimary_description__1Tind a {
  text-decoration: none;
  color: var(--brand);
}
.UpcomingEvent_EventPrimary_description__1Tind a:hover {
  text-decoration: underline;
  color: var(--brandHover);
}
.UpcomingEvent_EventPrimary_description__1Tind p {
  font-size: 1.1rem;
}

/* // ticket */
.UpcomingEvent_buyTicket__1Ypfl {
  display: flex;
  align-items: center;
  /* width: 88%; */
  /* margin: 0 auto; */
}
.UpcomingEvent_buyTicket__1Ypfl button {
  line-height: 150%;
  /* background-color: var(--brand); */
  /* color: var(--mainWhite); */
  /* height: 40px;
  max-height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.UpcomingEvent_buyTickets_info_button__wD53E{
  display: flex;
  justify-content: center;
  align-self: flex-end;
}
.UpcomingEvent_buyTicket__1Ypfl > * + * {
  margin-left: 10px;
}
.UpcomingEvent_buyTickets_info_button__wD53E  > * + *{
  margin-left: 10px;

  
}
.UpcomingEvent_buyTickets_info_input_null__oJ6En {
  visibility: hidden;
  opacity: 0;
}
.UpcomingEvent_buyTickets_info__3GL1P {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.UpcomingEvent_buyTickets_info__3GL1P > * + * {
  margin-top: 10px;
}
.UpcomingEvent_buyTickets_info_input__3d-5w {
  display: flex;
  justify-content: center;
  align-items: center;
}
.UpcomingEvent_buyTickets_info_input__3d-5w > * + * {
  margin-left: 10px;
}
.UpcomingEvent_buyTickets_info_input__3d-5w input {
  /* height: 40px; */
  padding: 12px 12px;
  width: 250px;
  /* max-height: 40px; */
  height: 50px;

}
.UpcomingEvent_buyTickets_info_btn__25oad {
  background-color: var(--mainBlack) !important;
}
@media (max-width: 1140px) {
  .UpcomingEvent_SecondaryContainer__1mQ3W {
    background-color: black;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 550px) {
  .UpcomingEvent_EventTertairy__3wzWV {
    width: 100%;

    margin: 0 auto;
    padding: 0 20px;
  }
  .UpcomingEvent_buyTickets_info_button__wD53E{
    flex-direction: column;
    width: 100%;
  }
  .UpcomingEvent_EventPrimary__1Annn {
   padding: 10px 0;
  }
  /* // ticket */
.UpcomingEvent_buyTicket__1Ypfl {
 flex-direction: column;
}
.UpcomingEvent_buyTickets_info_button__wD53E  > * + *{
  margin-left: 0;
  margin-top: 10px;



  
}
.UpcomingEvent_buyTicket__1Ypfl > * + * {
  margin-top: 20px;
}
.UpcomingEvent_buyTickets_info_input__3d-5w {
width: 100%;
}
.UpcomingEvent_buyTickets_info_input__3d-5w input {
width: 100%;
}
.UpcomingEvent_buyTickets_info__3GL1P {
 width: 100%;
}
.UpcomingEvent_EventSecondary__pF7no {
    width: 100%;
    margin: 0 auto;
  }
}

.button {
     
color: white;
cursor: pointer;

 padding: 10px 56px;
 text-decoration: none;
 border: none;
  font-size: var(--font16);
  border-radius: 12px;
  background-color:var( --brandHover);
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
  background-color:var( --brand);


  }
  
  .button--inverse {
    background: var(--mainWhite);
    color: var(--brand);
    border: 1px solid var(--brand);
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: var(--mainWhite);
  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: var(--disabledText);
  color: var(--mainWhite);
  cursor: not-allowed;
  }
  
  
.NavBarButton_activeSelected__1X6zD {
  background-color: var(--brandHover) !important;

  height: 50px;
  width: 100px;

}

.NavBarButton_FaIcon__RbRgT {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.NavBarButton_navBarSearch__3jG8E {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.NavBarButton_navBarSearch__3jG8E > * + * {
  margin-left: 2px;
}



@media (max-width: 991px) {
     
    .NavBarButton_activeSelected__1X6zD {
        /* background-color: var(--playButton) !important; */
      
        height: 50px;
        width: 200px;
        min-width: 200px;
      
      }
      
  }
.NavBar_navBarRow__2j6Bu {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
}
.NavBar_navBar__Zzc57 {
  display: grid;
  grid-template-columns: 0fr 1fr;
  height: 50px;
  width: 88%;
  align-items: center;
  color:  #ffffff;
  z-index: 5;
  margin: 0 auto;
  /* padding: 0 20px; */
  min-height: 50px;
}
.NavBar_navBar__Zzc57:first-child a {
  z-index: 9999999 !important;
}
.NavBar_navBar__Zzc57 a{
  text-decoration: none;
  list-style: none;
  color:  #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
 width: 100px  ;


}


.NavBar_navBarRow_primary__2O3dq {
  flex: 3 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.NavBar_navBarRow_primary__2O3dq li,
.NavBar_navRight__36-Tk li {
  text-decoration: none;
  list-style: none;
  color:  #ffffff;
}
.NavBar_navBarRow_primary__2O3dq li a,
.NavBar_navRight__36-Tk li a {
  text-decoration: none;
  list-style: none;
  color:  #ffffff;
  position: relative;
}

.NavBar_navRight__36-Tk {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  flex: 2 1;
  justify-content: flex-end;
  align-items: center;
}
.NavBar_navRight__36-Tk > * + * {
  margin-left: 20px !important;
}

@media (max-width: 1200px) {
  .NavBar_navBar__Zzc57 {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .NavBar_navBar__Zzc57 {
    justify-content: space-between;
  }
  .NavBar_navBar__Zzc57:first-child a {
    
    width: auto;
    color: #ffffff !important;
   

  }
   
  .NavBar_navBarRow__2j6Bu {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 400;
    justify-content: flex-end;
    max-height: 100%;
    /* overflow: hidden; */
  }
  .NavBar_navBarRow_primary__2O3dq {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .NavBar_navRight__36-Tk {
    width: 100%;
    justify-content: center;
  }
  .NavBar_navBarRow_primary__2O3dq li a,
  .NavBar_navRight__36-Tk li a {
    text-decoration: none;
    list-style: none;
    color: var(--mainBlack);
    position: relative;
    height: 50px;
    width: 200px;
    min-width: 200px;
  }
  .NavBar_navBar__Zzc57 {
    color: var(--mainBlack);
  }
}

.Banner_bannerContainer__2Apls {
  /* width: 90%;
  margin: 0 auto; */
}

.CategoriesWithTitles_categoriesContainer__3TjHq {
  /* width: 95%;
  margin: 0 auto 0 auto; */
}

.Promo_primarycontainer_Promo__3tIdd {
  padding: 10px 0 50px 0;
}
.Promo_secondaryContainer__1tkja {
  width: 100vw;
  margin: auto;
  color: var(--mainWhite);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  gap: 10px;
}
.Promo_container_multipleItem__3wWvG {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
/* media Queeries */
@media (max-width: 1050px) {
  .Promo_secondaryContainer__1tkja {
    width: 60vw;
    margin: auto;
    color: var(--mainWhite);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    gap: 10px;
  }
  .Promo_container_multipleItem__3wWvG {
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  }
  /* .secondaryContainer {
    width: 100vw;
    margin: 0 10px 0 10px;
  } */
}
@media (max-width: 550px) {
  .Promo_secondaryContainer__1tkja {
    width: 100vw;
    margin: auto;
    color: var(--mainWhite);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    gap: 10px;
  }
  .Promo_container_multipleItem__3wWvG {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    align-items: center;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
}

.wrapper-demo {
  flex: 1 1;
  width: 400px;
  max-width: 90vw;

  z-index: 200;
  /* background: var(--brand); */
}

/****-----start-wrapper-dropdown-2----****/
.wrapper-dropdown-2 {
  position: relative;
  padding: 17px 20px 17px 15px;
  cursor: pointer;
  outline: none;
  color: var(--brandHover);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 600;
  max-height: 50px;
  min-height: 50px;
}
.dropdown:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  right: 20px;
  top: -3%;
  margin-top: -6px;
  border-width: 0px 12px 12px 12px;
  border-style: solid;
  border-color: var(--brandHover) var(--background);
}

.wrapper-dropdown-2 .dropdown {
  /* Size & position */
  position: absolute;
  top: 136%;
  left: 0px;
  right: 0px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  /* Styles */
  background: var(--brandHover);
  transition: all 0.3s ease-out;
  list-style: none;
  z-index: 1;

  /* Hiding */
  opacity: 0;
  pointer-events: none;
}
.wrapper-dropdown-2 .dropdown li:last-child {
  border-bottom: none;
}
.wrapper-dropdown-2 .dropdown li {
  transition: all 0.3s ease-out;
}
.wrapper-dropdown-2 .dropdown li div {
  display: block;
  text-decoration: none;
  color: var(--mainWhite);
  padding: 15px;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
  font-weight: 600;
}
.dropdown li div:hover {
  /* color: var(--brand); */
}
.dropdown li div:hover .icon {
  background-position: 0px 0px;
}
/* Hover state */
.wrapper-dropdown-2 .dropdown li:hover {
  background: var(--mainBlack);
}
.wrapper-dropdown-2 .dropdown li:nth-child(1) {
  border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -o-border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -o-border-top-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:last-child {
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  -o-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  -o-border-bottom-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:nth-child(2),
.wrapper-dropdown-2 .dropdown li:nth-child(3),
.wrapper-dropdown-2 .dropdown li:nth-child(4) {
  border-radius: none;
}
/* Active state */
.wrapper-dropdown-2.active .dropdown {
  opacity: 1;
  pointer-events: auto;
  max-height: 25rem;
  overflow: scroll;
}
.active {
  background: var(--brandHover) !important;
  color: var(--mainWhite);
}

.wrapper {
  margin: 20px auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* VIA CSS MATIC https://goo.gl/cIbnS */
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
}

.info {
  display: flex;
  align-items: center;
}

.StateView_stateViewContainer__uTOmo {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.StateView_stateView__1_OZg {
  border-bottom: 2px solid var(--mainBlack);
  color: var(--mainBlack);
  text-align: center;
  width: calc(33% - 1em);
  font-size: 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StateView_stateView__1_OZg:not(:last-child)::before {
  display: block;
  content: ' \003E';
  
  height: 1em;
  width: 1em;
  line-height: 1em;
  border-radius: 100%;
  margin-bottom: 0.25rem;
  text-align: center;
  z-index: 1;
  font-size: 18px;
  border: 4px solid;
  padding: 4px;
  position: absolute;
  bottom: -20px;
  /* right: -30px; */
  right: calc(33% - (33% + 2em));
  color: var(--gray);
}
.StateView_stateViewSelected__29JjS:not(:last-child)::before {
  color: var(--brand);
}

.StateView_stateView__1_OZg:hover {
  cursor: pointer;
}

.StateView_stateViewSelected__29JjS {
  border-bottom: 2px solid var(--brand);
  color: var(--brand);
}




@media (max-width: 550px) {
  .StateView_stateViewContainer__uTOmo {
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
     
  }
  .StateView_stateViewContainer__uTOmo>  *+*{
    
  margin-top: 50px;
     
  }
  .StateView_stateView__1_OZg {
    width: 100%;
    border-bottom: 0;
    justify-content: flex-start;
  }

  .StateView_stateView__1_OZg:not(:last-child)::before {
   top: 40px;
   bottom: 0;
   right: 0;
   left: 0;
   content: ' \25be';
   
  }
  
  
  
   
}



.PackageComponent_packageComponent___VcaY {
  width: 28%;
  min-width: 300px;
  border: 2px solid var(--brand);
  border-radius: 4px;
  /* margin: 0px 2% 2%; */
  background-color: var(--mainWhite);
  height: 420px;
  padding: 20px 10px;
  position: relative;
}

.PackageComponent_packageTitle__1yLIm {
  color: var(--brand);
  text-align: center;
  /* font-size: 1.5rem; */
  font-weight: bold;
}

.PackageComponent_packagePrice__3YYPd {
  color: var(--brand);
  text-align: center;
  /* font-size: 2rem; */
  font-weight: bold;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 20px;
  padding: 35px 0;
  word-wrap: break-word;
}

.PackageComponent_packageDescription__s0OLZ {
  text-align: center;
  /* font-size: 1rem; */
  color: var(--mainBlack);
  /* height: 200px; */
  min-height: 250px;
}

.PackageComponent_packageBuyButtonContainer__Y-S2o {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  position: absolute;
  bottom: 0;
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  justify-content: center;
  /* right: 50%; */
  /* left: 50%; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  top: le;
  left: 20%;
  padding: 0;
  bottom: 10px;

}

.PackageComponent_packageBuyButton__3OYIB {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
}

.PackageComponent_packageBuyButton__3OYIB:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PackageComponent_packageBoughtButton__2E5eK {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  /* font-size: 1rem; */
  border-radius: 4px;
  display: inline-block;
}

.PackageComponent_popupEmailForm__1jBUD {
  width: 100%;
}
.PackageComponent_popupEmailForm__1jBUD input {
  padding: 11px 10px;
  border: none;
  /* background: #12161d; */
  color: var(--mainBlack);
  /* font-family: 'Gotham Greek Light', sans-serif !important; */
  /* // safari support */

  -webkit-appearance: initial;
}
.PackageComponent_emailConfirmModalButton__2whaS{

  display: flex;
  align-items: center;
}

.PackageComponent_emailConfirmModalButton__2whaS *+*{
  margin-left: 10px;
}
.PackagesView_packagesView__3WoaF {
}

.PackagesView_packages__3B3n_ {
  width: 88%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  margin-bottom: 150px;
}

.PackagesView_packageHelp__1kEG0 {
  width: 88%;
  margin: 0 auto;
}

.PackagesView_packageHelpTitle__2JdCN {
  color: var(--mainBlack);
  font-size: 1.5rem;
  /* width: 88%; */
  margin: 0 auto;
  text-align: left;
}

.PackagesView_packageHelpText__2plaO {
  color: var(--mainBlack);
  /* width: 88%; */
  margin-bottom: 50px;

  padding: 30px 30px;
  background-color: whitesmoke;
}
.PackagesView_packagesView__3WoaF input{
  height: 50px;
}

.PackagesView_packageHelp_input__-iwYL{
  display: flex;
  width: 60%;
  margin: 20px 40% 0 0;

}


@media (max-width: 550px) {
  .PackagesView_packageHelp_input__-iwYL{
    display: flex;
    width: 100%;
    margin: 20px 0 0 0;
    flex-direction: column;
  
  }
  .PackagesView_packages__3B3n_ {
    justify-content: center;
  }
}

/* for the background color of the form and image */
.Input_iconColor__1uXlL {
  width: 100%;
  height: 100%;
  font-size: large;
  color: var(--white);
}
.Input_formPrimary__6N8NP {
  background-color: var(--background);
  width: 100vw;
  color: var(--white);
  /* height: 100vh; */
}

.Input_loginRow__17GAD {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  color: var(--white);
  flex-direction: column;
}
.Input_loginRow__17GAD a {
  text-decoration: none;
  color: var(--white);
  border-bottom: 1px solid white;
}
.Input_loginRow__17GAD a:hover {
  /* color: var(--brand); */
  opacity: 0.7;
  border-bottom:none;

}
.Input_signUpConditionsRow__NJvrs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Input_signUpConditionsRow__NJvrs a {
  color: var(--white);
  text-decoration: none;
  padding: 10px;
}
.Input_signUpConditionsRow__NJvrs a:hover {
  /* color: var(--brandHover); */
  border-bottom: 1px solid white;
  transition: all 0.5s ease-in-out;
  /* transform: scale(1.1); */
}
.Input_signUpFormContainer__34MH6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}
.Input_formSignUpPrimary__W5spN {
  width: 50%;
}

/* // for image and form to align flex */
.Input_formSecondary__1VJs5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* // image  */
.Input_formSecondary__1VJs5 img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
/* main form */

.Input_form__3wfhg {
  width: 70%;
  height: auto;
  margin: 10% 15%;
  border: 1px solid var(--white);
  background-color: var(--white);
  padding: 20px;
}

/* //form blog is for big text area input like creatign blogs */
.Input_formBlog__2qGet {
  width: 50%;
  height: auto;
  margin: 50px 20% 0 20%;
  border: 1px solid var(--brandHover);
  background-color: var(--brandHover);
  padding: 20px;
}

/* // input of form */
.Input_formSecondary__1VJs5 input,
.Input_formSecondary__1VJs5 textarea,
.Input_formSecondary__1VJs5 select {
  padding: 11px 10px;
  border: none;
color: #12161d;
background: var(--white);
  
  /* // safari support */

  -webkit-appearance: initial;
}

.Input_formSecondary__1VJs5 textarea::-webkit-input-placeholder, .Input_formSecondary__1VJs5 input::-webkit-input-placeholder {
  font-size: initial;
  color: var(--brand) !important;
}

.Input_formSecondary__1VJs5 textarea::placeholder,
.Input_formSecondary__1VJs5 input::placeholder {
  font-size: initial;
  color: var(--brand) !important;
}
/* // focus of input */
.Input_formSecondary__1VJs5 input:focus,
.Input_formSecondary__1VJs5 textarea:focus {
  outline: none;
}
/* // icon and input together */

.Input_formInput__2xwhO {
  width: 80%;
  background: var(--brand);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 20px auto;
}

.Input_profilePageInput__2qmVq {
  width: 80%;
  background: var(--brand);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 15px auto;
}

/* // invalid form input */
.Input_formInputInvalid__2uck_ {
  background-color: var(--red) !important;
  color: var(--white) !important;
  border: 1px solid var(--brand) !important;
}
/* // focusing the whole icon and input when focused upon inpu */
.Input_formInput__2xwhO:focus-within {
  background-color: var(--brand);
  border: 1px solid var(--white);
}
.Input_formInput__2xwhO {
  background-color: var(--brand);
  border: 1px solid var(--brand);
}

/* // h2 */
.Input_formHeader__2W4GL {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
.Input_formHeader__2W4GL h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */
.Input_formHeader__2W4GL::after {
  background-color: var(--brand);
}

/* // link textxx */
.Input_linkText__3BKNi {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 80%;
  margin-top: 20px;
}
/* // for image file button margin */
.Input_formInputImageUploadInput__1iFG1 {
  margin-left: 20px;
}

/* // error text */
.Input_errorText__2_kXY {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.Input_errorText__2_kXY::before {
  /* display: inline; */
  content: '⚠  \00a0  ';
}

.Input_loginFormButton__31lku {
  background: var(--brand);
  color: var(--white);
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Input_loginFormButton__31lku:hover {
  background: var(--brandHover);
  outline: 1px solid white;

}

@media (max-width: 799px) {
  .Input_form__3wfhg {
    width: 60%;
    height: auto;
    margin: 10% 20%;
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .Input_formSecondary__1VJs5 img {
    display: none;
  }
  .Input_formBlog__2qGet {
    width: 80%;
    height: auto;
    margin: 50px 10% 0 10%;
  }
}
@media (max-width: 1025px) {
  .Input_formSecondary__1VJs5 img {
    width: 30%;
  }

  .Input_form__3wfhg {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .Input_formSecondary__1VJs5 img {
    display: none;
  }
  .Input_form__3wfhg {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .Input_formInput__2xwhO {
    width: 100%;
  }
  .Input_formBlog__2qGet {
    width: 90%;
    margin: 5%;
  }
  .Input_formSecondary__1VJs5 {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .Input_form__3wfhg {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
    margin: 0;
  }
  .Input_formBlog__2qGet {
    margin: 2.5%;
    width: 95%;
  }

  .Input_formInput__2xwhO {
    width: 100%;
  }
  .Input_formPrimary__6N8NP {
    height: 70vh;
    padding-bottom: 5rem;
  }
}

/* Change text in autofill textbox
input:-webkit-autofill {
  -webkit-text-fill-color: var(--white) !important;
  background-color: red !important;
  background: red !important;
} */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--white) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--brandHover);
}

.PaymentMethod_paymentMethodContainer__3E0oa {
  width: 100%;
  margin-top: 20px;
}

.PaymentMethod_paymentMethodBuy__2Fm-0 {
  cursor: pointer;
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
}

.PaymentMethod_paymentMethodImage__3mnxH {
  max-height: 80px;
  max-width: 100%;
}


@media (max-width:550px) {
    .PaymentMethod_paymentMethodContainer__3E0oa {
      margin-top: 0
    }
  
}
.SelectedPackage_selectedPackage__U79Ut{
    background-color: whitesmoke;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.SelectedPackage_selectedPackage__U79Ut >*+*{
    margin-top: 20px;
}
 

.SelectedPackage_selectedPackagePrimary__3LsYK{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.SelectedPackage_selectedPackagePrimary__3LsYK >*+*{
  flex: 1 1;
  margin-right: 40px;
}
.SelectedPackage_selectedPackageList_name__1ItY7{
    flex: 4 1;
}
.SelectedPackage_selectedPackagePrimaryHeading__3jvRx{
    font-weight: 700;
}


@media (max-width:550px) {
    .SelectedPackage_selectedPackagePrimary__3LsYK{
       flex-direction: column;
    }
    .SelectedPackage_selectedPackagePrimary__3LsYK >*+*{
        margin-right: 0;
        margin-top: 20px;
      }
    .SelectedPackage_selectedPackage__U79Ut{
       flex-direction: row;
    }
    .SelectedPackage_selectedPackageList_name__1ItY7{
        flex: 10 1;
    }
    .SelectedPackage_selectedPackage__U79Ut >*+*{
        margin-top: 0;

    }

     
    
    
}
.VoucherView_voucherTitle__24Woj {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.VoucherView_voucherButtons__2tjNn {
  margin: 0 auto;
  display: flex;
}

.VoucherView_voucherButtons__2tjNn * +*{
  margin-left: 10px;
}

 
 

.VoucherView_voucherApiError__2chhB {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--red);
}
.VoucherView_voucherInput__2Ndd5 input{
  padding: 11px 10px;
}
.PaymentMethodsView_payment__1B62N {
  width: 88%;
  margin: 30px auto;
}

.PaymentMethodsView_paymentTitle__1Zm2W {
  color: var(--mainBlack);
  /*  */
  margin: 10px auto;
  text-align: left;
}

.PaymentMethodsView_paymentHelp__Vy71x {
}

.PaymentMethodsView_paymentUserNotLoggedIn__vXT3O {
  padding: 20px 0 0;
  color: var(--red);
}

.PaymentMethodsView_paymentMethodsContainer__1f7K- {
  margin-top: 30px;
  position: relative;
}

.PaymentMethodsView_paymentHideMethods__1v9px {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
}

.PaymentMethodsView_paymentMethodsTitle__gvl7n {

  margin: 30px auto 10px;
  color: var(--mainBlack);
  /* font-size: 1.5rem; */
  text-align: left;
}

.PaymentMethodsView_paymentMethodHelp__3WvnV {

  margin: 0 auto;
}

.PaymentMethodsView_paymentMethodError__3Kiom {

  margin: 0 auto;
  color: var(--red);
}

.PaymentMethodsView_paymentMethods__1rKG6 {

  /* margin: 10px auto; */
  display: flex;
  /* flex-direction: column; */
  border: 1px solid var(--brand);
  padding: 20px 20px 10px;
  width: 500px;
  /* height: 158px; */
  margin: 50px 0;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  /* align-items: center; */
  height: 158px;
  border-radius: 12px;
}
.PaymentMethodsView_paymentMethods_Voucher__1ImQM{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--brand);
  padding: 20px 20px 10px;
  width: 500px;
  /* height: 158px; */
  margin: 50px 0;
  grid-gap: 20px;
  gap: 20px;
  border-radius: 12px;


}


@media (max-width:550px) {
  
  .PaymentMethodsView_paymentMethods__1rKG6 {

    display: flex;
    flex-direction: column;
    width: auto;
    
    
  }
  .PaymentMethodsView_paymentMethods__1rKG6 {

    
    height: auto;
  }
}
.Paytrail_PaytrailButton__vatbP{
   height: 100%;
   width: 100%;
    padding: 10px;
  
}
.Paytrail_PaytrailForm__3KM2C{
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 50px;
  row-gap: 50px;
  color: var(--mainBlack);
  /* justify-content: center; */
}

.Paytrail_PaytrailGroups__37h6A{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
}

.Paytrail_paymentMethodTitle__2Knua{
    margin: 50px auto;
  color: var(--brand);
  font-weight: 800;

}

.Paytrail_terms__2T74l{
    color: var(--mainBlack);
     margin: 50px auto;
  

}
.Paytrail_terms__2T74l a{
    color: var(--brand);
}


@media (max-width:550px) {
  
    .Paytrail_PaytrailForm__3KM2C{
        display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 150px));
      grid-gap: 30px;
      gap: 30px;
      /* justify-content: center; */
    }
    .Paytrail_paymentMethodTitle__2Knua{
        margin: 20px auto;
      
    
    }
  }
.ReceiptView_receiptView__Vis9D {
  width: 80%;
  margin: 30px auto;
}

.ReceiptView_receiptTitle__2Fqe9 {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.ReceiptView_receiptText__20EoT {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptFailureMessage__gTFch {
  width: 80%;
  margin: 0 auto;
  color: var(--red);
}

.ReceiptView_receiptReturnPath__8m_My {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptReturnButton__pIO1t {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
}

.ReceiptView_receiptReturnButton__pIO1t:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PurchaseFlow_packageMain__3gJSl {
  width: 100%;
  display: block;
}

.PurchaseFlow_packages__2fE6- {
  width: 88%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PurchaseFlow_pageState__1m2sd {
  width: 88%;
  margin: 0 auto;
}

.TOS_tosContainer__33WRN {
    margin: 5% auto 2% auto;
    width: 80%;
    flex-grow: 1;
    color: var(--mainBlack);
  }
  
  .TOS_padded__2UCMv {
    padding-left: 1%;
  }
  .TOS_padded__2UCMv li {
    padding: 5px 0;
  }
  
  .TOS_tosMainTitle_p__3onAv {
    line-height: 28px;
  }
  .TOS_tosTitle__16PBu {
    font-weight: 700;
    padding-bottom: 10px;
  }
  .TOS_tosMainTitle__FdB9Z {
    padding-bottom: 10px;
    font-weight: 700;
  }
  .TOS_tosMainTitle_p__3onAv a {
    color: var(--brandHover);
  }
  
  .TOS_tosContainer__33WRN img{
    height: 100%;
    width: 100%;
    max-width: 991px;
    margin: 0 auto;
  }
.PrivacyPolicy_tosContainer__k5jgm {
  margin: 5% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--mainBlack);
}

.PrivacyPolicy_padded__3Utmk {
  padding-left: 1%;
}
.PrivacyPolicy_padded__3Utmk li {
  padding: 5px 0;
}

.PrivacyPolicy_tosMainTitle_p__3VRHm {
  line-height: 28px;
}
.PrivacyPolicy_tosTitle__1qAkL {
  font-weight: 700;
  padding-bottom: 10px;
}
.PrivacyPolicy_tosMainTitle__3r3Kf {
  padding-bottom: 10px;
  font-weight: 700;
}
.PrivacyPolicy_tosMainTitle_p__3VRHm a {
  color: var(--brandHover);
}

/* 
.searchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.resultButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
} */
.Search_resultsAmount__2uJeE {
  align-self: center;
  margin-top: 1%;
}

.Search_searchRowNewOrAlphabetContainer__raBkp {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.Search_searchResultBTN__8GM93 {
  color: var(--white);
  background: var(--gray);
  cursor: pointer;
  margin-left: 10px;
}
.Search_searchResultBTN__8GM93:hover,
.Search_searchResultBTNactive__3Ep9E:hover {
  color: var(--brand);
  background: var(--brandHover);
}
.Search_form_button__2S5sJ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /*gap: 10px; stupid safari */
}

.Search_searchUpper__U9uoH {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Search_searchResultBTNactive__3Ep9E {
  color: var(--white);
  cursor: pointer;
  background: var(--brand);
  margin-left: 10px;
}

.Search_searchLabel__2tgvb {
  margin-bottom: 0.25rem;
}

.Search_searchCheckbox__2mzQ7 {
  margin-right: 0.25rem;
}

.Search_searchCheckboxLabel__1Lhf1 {
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 1%;
  white-space: nowrap;
}

.Search_searchSelectCategory__3j2YQ {
  padding: 0.25rem;
}

.Search_searchContainer__iU_At {
  display: flex;
  flex-direction: column;
  color: var(--mainBlack);
  width: 60%;
  margin: 0 auto;
  padding: 20px 40px;
}
.Search_row__1pqJZ {
  display: flex;
  color: var(--mainBlack);
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.Search_displayFlexCenter__1YHvF {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search_searchColumn__2rSXY {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  width: 100%;
}

.Search_bottomContainer__bDtdk {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.Search_searchButton__1f5Q5 {
  color: var(--white);
  background-color: var(--brandHover);
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

.Search_FaIcon__3HShP {
  margin-right: 5px;
}

.Search_searchButton__1f5Q5:hover {
  background-color: var(--brand);
}

.Search_searchField__2UCJ3 {
  padding: 18px 0;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  text-align: center;
  border-radius: 12px;
}

.Search_searchRow__1SMol {
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}
.Search_search_form_label__2FPws {
  display: flex;
}
#Search_searchForm__XlZDF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.Search_searchTitle__ziCZD {
  margin-bottom: 2rem;
}

.Search_resultsGrid__2ykHr {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 1%;
}
@media (max-width: 1180px) {
  .Search_searchRow__1SMol {
    width: 100%;
  }
  .Search_search_form_label__2FPws {
    margin-bottom: 2%;
  }
  .Search_searchRowNewOrAlphabetContainer__raBkp {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .Search_searchContainer__iU_At {
    width: 95%;
    padding: 20px 20px;
  }
}
@media (max-width: 700px) {
  .Search_searchButton__1f5Q5,
  .Search_searchResultBTN__8GM93,
  .Search_searchResultBTNactive__3Ep9E {
    padding: 10px 20px !important;
  }
}

@media (max-width: 550px) {
  .Search_searchContainer__iU_At {
    padding: 5px;
  }
  .Search_searchRow__1SMol {
    width: 95%;
    padding: 0px;
    margin: 0 auto;
  }
  .Search_row__1pqJZ {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
}

.DetailsShare_details_share__1Yzlp {
  display: flex;
  /* padding-top: 35px; */
  flex-wrap: wrap;
  width: 100%;
  /* gap: 20px; */
  align-items: center;
  justify-content: flex-start;
}
.DetailsShare_details_share__1Yzlp a {
  text-decoration: none;
  color: var(--brand);
}
.DetailsShare_details_share__1Yzlp a:hover {
  color: var(--brandHover);
}

.DetailsShare_shareBTN__31UYI {
  background-repeat: no-repeat;
  background-size: cover;

  width: 8.57px;
  height: 16px;
  cursor: pointer;
  margin-right: 10px;
  color: var(--playButton);
  display: flex;
  justify-content: center;
  align-items: center;
}


.DetailsShare_shareBTN__31UYI:hover {
    
    color: var(--mainWhite);
     
  }
  
.DetailsVideo_details_description_container__1c0ao {
  width: 88%;
  margin: 0 auto;
  background-color: var(--brandHover);
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--mainWhite) !important;

}
.DetailsVideo_details_description_container__1c0ao > * + * {
  margin: 23px 0;
}
.DetailsVideo_detailsDescriptionTitleContainer__dDfRv {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--playButton);
  /* padding: 10px; */
  overflow: hidden;
  align-items: center;
}
.DetailsVideo_details_description_title_name__g8_vc {
  /* text-transform: uppercase; */
  font-weight: bold;
  color: var(--mainWhite);

  line-height: 33.6px;
}

.DetailsVideo_details_description_title_left__JAwit {
  flex: 2 1;
  margin: 23px 0;
}
.DetailsVideo_details_description_title_right__1rrSE {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .DetailsVideo_details_description_container__1c0ao {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .DetailsVideo_details_description_container__1c0ao {
    width: 95%;
    padding: 15px;
  }
}

.ProfileHeader_ProfileHeader__cPe5Y {
  display: flex;
  justify-content: center;
  color: var(--white);
}
.ProfileHeader_ProfileHeader__cPe5Y h2 {
  margin-bottom: 5px !important;
  padding-bottom: 0;
  margin-right: 10px;
}

/* for the background color of the form and image */
.Profile_iconColor__1WH8S {
  /* font-size: 1rem; */
  width: 100%;
  height: 100%;
}
.Profile_formPrimary__2lYMp {
  background-color: var(--darkBlue);
  width: 100vw;
  height: 100vh;
}

/* // for image and form to align flex */
.Profile_formSecondary__I_oZP {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Profile_profileContainer__pKogk {
  width: 80%;
  flex-grow: 1;
  /* padding-top: 10px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  color: black;
}

.Profile_profileMenuOption__2H6NT {
  padding: 1rem 1rem 1rem 1em;
  white-space: nowrap;
  color: var(--silver);
}

.Profile_profileMenuOptionSelected__nPEa5 {
  color: var(--brand);
  padding: 1rem 1rem 1rem 1em;
  white-space: nowrap;
}

.Profile_profileMenuOption__2H6NT:hover {
  cursor: pointer;
}

.Profile_profileMessage__3zW8p {
  margin: auto;
}

.Profile_profileOptionView__3TBVt {
  color: black;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/* //form blog is for big text area input like creatign blogs */
.Profile_formBlog__3ffU5 {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 5% 20%;
  border: 2px solid var(--card);
  background-color: var(--card);
  padding: 20px;
  border-radius: 0.5rem;
}

/* // input of form */
.Profile_formSecondary__I_oZP input,
.Profile_formSecondary__I_oZP textarea {
  padding: 11px 10px;
  border: none;
}

.Profile_formSecondary__I_oZP textarea::-webkit-input-placeholder, .Profile_formSecondary__I_oZP input::-webkit-input-placeholder {
  font-family: initial;
  font-size: initial;
}

.Profile_formSecondary__I_oZP textarea::placeholder,
.Profile_formSecondary__I_oZP input::placeholder {
  font-family: initial;
  font-size: initial;
}
/* // focus of input */
.Profile_formSecondary__I_oZP input:focus,
.Profile_formSecondary__I_oZP textarea:focus {
  outline: none;
}

/* // h2 */
.Profile_formHeader__2ksCk {
  text-align: center;
  text-transform: uppercase;
}

.Profile_formHeader__2ksCk::after {
  height: 4px;
  display: block;
  content: '';
  width: 120px;
  background-color: var(--brand);
  margin: 20px auto 0 auto;
}
@media (max-width: 1145px) {
  .Profile_formSecondary__I_oZP img {
    width: 30%;
  }
  .Profile_profileContainer__pKogk {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 799px) {
  .Profile_formSecondary__I_oZP img {
    display: none;
  }
  .Profile_profileContainer__pKogk {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .Profile_formSecondary__I_oZP img {
    display: none;
  }
  .Profile_profileContainer__pKogk {
    width: 100%;
    margin: auto;
  }
  .Profile_formSecondary__I_oZP {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .Profile_formPrimary__2lYMp {
    height: 70vh;
    padding-bottom: 5rem;
  }
}

.FAQ_tosContainer__Nf3Zk {
    margin: 5% auto 2% auto;
    width: 80%;
    flex-grow: 1;
    color: var(--mainBlack);
  }
  
  .FAQ_padded__1q5G5 {
    padding-left: 1%;
  }
  .FAQ_padded__1q5G5 li {
    padding: 5px 0;
  }
  
  .FAQ_tosMainTitle_p__3TFA_ {
    line-height: 28px;
  }
  .FAQ_tosTitle__OEgZD {
    font-weight: 700;
    padding-bottom: 10px;
  }
  .FAQ_tosMainTitle__RwGqV {
    padding-bottom: 10px;
    font-weight: 700;
  }
  .FAQ_tosMainTitle_p__3TFA_ a {
    color: var(--brandHover);
  }
  
  .FAQ_tosContainer__Nf3Zk img{
    height: 100%;
    width: 100%;
    max-width: 991px;
    margin: 0 auto;
  }
