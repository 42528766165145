.Footer {
  
   
  background-color: var(--brand);
}
.FooterPrimary {
  display: flex;
  width: 88%;
  margin: 0 auto;
  line-height: 20px;
  padding: 25px 0px 20px 0px;
}
.FooterPartner{
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 20px 30px;
    
}
.FooterPartner a{
    text-decoration: none;
}

.FooterPartnerWrapper{
    display: flex;
}
.FooterPartnerWrapper img{
/*    
   max-width: 100%;
   height: 100% !important; */
   width: 100%;
   height: 100%;


}
.FooterPartnerWrapper a:hover{
  cursor: pointer;
       
    
    }

 .FooterOtherPartner .FooterPartnerWrapper a,.FooterOtherPartner .FooterPartnerWrapper div{
    margin:20px 26px ;

}
 .FooterMainPartner .FooterPartnerWrapper a,.FooterMainPartner .FooterPartnerWrapper div{
    margin:40px 26px ;
}
  .FooterPartnerWrapper  {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0 10px;

}

.FooterOtherPartner,.FooterMainPartner{
    border-bottom: 1px solid #f0f0f0;
}

.FooterOtherLogo{
  padding:  43px 20px 80px;
}

.FooterOtherLogo img{
    height: 64px;
    width: 64px;
}
.FooterOtherLogo_LogoAndText,.FooterOtherLogo_Logo{
    width: 33%;
}
.FooterOtherLogo_LogoAndText{
    display: flex;
   text-align: left;

}
.FooterOtherLogo_LogoAndText p{
    margin: 0 0 0 15px;
    color: #666;
    max-width: 154px;
    font-size: 12px;
    line-height: 16px;

}
.FooterOtherLogo_Logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.FooterPartner_img8{
    margin-top: 60px;
}
.Footer_Description_Heading,
.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  height: 52px;
  letter-spacing: 0.5px;
  color: var(--playButton);
  font-weight: 500;
}

.Footer_Description_Heading{
  position: relative;

}
.Footer_Menu_Links{
  position: relative;
}
 
 
.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  display: flex;
  align-items: center;
  font-weight: 800;
}
.Footer_Menu a,
.Footer_SocialMedia a {
  color: var(--mainWhite);
  text-decoration: none;
}
.Footer_Menu a:hover,
.Footer_SocialMedia a:hover {
  color: var(--playButton);
  text-decoration: none;
  transition: 0.1s ease;
}
.Footer_Menu,
.Footer_SocialMedia {
  flex: 1;
}

.Footer_SocialMedia_Heading_Info,
.Footer_Menu_info {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: var(--mainWhite);
  /* gap: 10px; */
  align-items: flex-start;
  justify-content: center;
}
.Footer_SocialMedia_Heading_Info > * + * {
  margin-top: 10px !important;
}
.Footer_Menu_info > * + * {
  margin-top: 10px !important;
}

.Footer_Description {
  color: var(--mainWhite);

  flex: 3;
}
.Footer_Description_Heading_Info {
  padding: 10px 50px 10px 0;
}
.Footer_SocialMedia_Links {
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  color: var(--mainWhite);
}

.Footer_SocialMedia_Links > * + * {
  margin-left: 10px;
}
.Footer_SocialMedia_Links_icons {
  color: var(--mainWhite);
}
/* media Queeries */
@media (max-width: 1200px) {
  .FooterPrimary {
    width: 95%;
  }
}
@media (max-width: 600px) {
  .FooterPrimary {
    flex-direction: column;
    width: 95%;
  }
  .Footer_Description_Heading{
    height: 0;
  }
  .Footer_Description_Heading_Info {
    padding: 0 10px 0 0;
  }
  .Footer_SocialMedia_Heading_Info,
  .Footer_Menu_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 0px; */
  }
  .Footer_SocialMedia_Heading_Info > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .Footer_Menu_info > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .FooterOtherLogo_LogoAndText{
    width: 100%;
    margin-bottom: 40px;
    justify-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}


}

@media (max-width: 450px) {


.FooterPartner_img8{
    margin-top: 0px;
}

.FooterMainPartner .FooterPartnerWrapper a{
    margin:30px 15px ;
}



}