.packagesView {
}

.packages {
  width: 88%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  gap: 30px;
  align-items: center;
  margin-bottom: 150px;
}

.packageHelp {
  width: 88%;
  margin: 0 auto;
}

.packageHelpTitle {
  color: var(--mainBlack);
  font-size: 1.5rem;
  /* width: 88%; */
  margin: 0 auto;
  text-align: left;
}

.packageHelpText {
  color: var(--mainBlack);
  /* width: 88%; */
  margin-bottom: 50px;

  padding: 30px 30px;
  background-color: whitesmoke;
}
.packagesView input{
  height: 50px;
}

.packageHelp_input{
  display: flex;
  width: 60%;
  margin: 20px 40% 0 0;

}


@media (max-width: 550px) {
  .packageHelp_input{
    display: flex;
    width: 100%;
    margin: 20px 0 0 0;
    flex-direction: column;
  
  }
  .packages {
    justify-content: center;
  }
}
