
.pricing{
    display: flex;
}
.pricing img{
    max-width: 650px;
    margin: 0 auto;
    width: 650px;

    justify-content: center;

 
}

@media (max-width:650px) {
    .pricing img{
        width: 100%;
    }
    
}