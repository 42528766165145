.voucherTitle {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.voucherButtons {
  margin: 0 auto;
  display: flex;
}

.voucherButtons * +*{
  margin-left: 10px;
}

 
 

.voucherApiError {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--red);
}
.voucherInput input{
  padding: 11px 10px;
}