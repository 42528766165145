/* for the background color of the form and image */
.iconColor {
  /* font-size: 1rem; */
  width: 100%;
  height: 100%;
}
.formPrimary {
  background-color: var(--darkBlue);
  width: 100vw;
  height: 100vh;
}

/* // for image and form to align flex */
.formSecondary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profileContainer {
  width: 80%;
  flex-grow: 1;
  /* padding-top: 10px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  color: black;
}

.profileMenuOption {
  padding: 1rem 1rem 1rem 1em;
  white-space: nowrap;
  color: var(--silver);
}

.profileMenuOptionSelected {
  color: var(--brand);
  padding: 1rem 1rem 1rem 1em;
  white-space: nowrap;
}

.profileMenuOption:hover {
  cursor: pointer;
}

.profileMessage {
  margin: auto;
}

.profileOptionView {
  color: black;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/* //form blog is for big text area input like creatign blogs */
.formBlog {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 5% 20%;
  border: 2px solid var(--card);
  background-color: var(--card);
  padding: 20px;
  border-radius: 0.5rem;
}

/* // input of form */
.formSecondary input,
.formSecondary textarea {
  padding: 11px 10px;
  border: none;
}

.formSecondary textarea::placeholder,
.formSecondary input::placeholder {
  font-family: initial;
  font-size: initial;
}
/* // focus of input */
.formSecondary input:focus,
.formSecondary textarea:focus {
  outline: none;
}

/* // h2 */
.formHeader {
  text-align: center;
  text-transform: uppercase;
}

.formHeader::after {
  height: 4px;
  display: block;
  content: '';
  width: 120px;
  background-color: var(--brand);
  margin: 20px auto 0 auto;
}
@media (max-width: 1145px) {
  .formSecondary img {
    width: 30%;
  }
  .profileContainer {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 799px) {
  .formSecondary img {
    display: none;
  }
  .profileContainer {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .formSecondary img {
    display: none;
  }
  .profileContainer {
    width: 100%;
    margin: auto;
  }
  .formSecondary {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .formPrimary {
    height: 70vh;
    padding-bottom: 5rem;
  }
}
