 
 /* link which is inside ItemLink */
.ItemLink a {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
  }
  .ItemLink a:hover {
    transition: all ease 1s;
    cursor: pointer;
    color: var(--brandPressed);
  
  }
 /* which does not include a href */


  .ItemLink  {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    font-weight: 800;
  }
  .ItemLink:hover {
    transition: all ease 1s;
    cursor: pointer;
    color: var(--brandPressed);
  
  }