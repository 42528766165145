.ViewedSectionPrimary {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
/* .ViewedSectionSecondary {
  width: 80%;
  margin: auto;
} */

.ViewedSectionSecondary h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
  color: var(--mainBlack);
}
.ViewedSectionSecondary h2:hover {
}
.ViewedSectionSecondary h2::after {
  background-color: var(--mainBlack);
}

.categoryList {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ViewedSectionSecondary .categoryList ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.categoryList ul li {
  color: #999999;
  padding-top: 12px;
}
.ViewedSectionSecondary__3videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  gap: 25px;
}
.ViewedSectionSecondary__3videos__image,
.ViewedSectionSecondary__2videos__image {
  /* margin-bottom: 20px; */
  position: relative;
  overflow: hidden;
  /* height: 200px;
  min-height: 200px;
  max-height: 200px; */
}
.ViewedSectionSecondary__3videos__image img {
  width: 100%;
  height: 100%;
  /* min-height: 190px; */
}
.ViewedSectionSecondary__3videos__image_overlay {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.wrapper_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.wrapper_bottom_likes,
.wrapper_bottom_duration {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  display: flex;
  gap: 5px;
}

.wrapper_bottom_likes {
  margin-left: 10px;
}
.wrapper_bottom_duration {
  margin-right: 10px;
}

.wrapper_bottom_likes > * + * {
  margin-right: 10px;
}
.ViewedSectionSecondary__2videos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.secondaryClassName {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ViewedSectionSecondary__3videos__main img,
.ViewedSectionSecondary__2videos__main img {
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  width: 100%;
}
.ViewedSectionSecondary__3videos__videoicon_overlay {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.699); */
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ViewedSectionSecondary__2videos__image:hover
  .ViewedSectionSecondary__3videos__videoicon_overlay {
  opacity: 1;
}
.ViewedSectionSecondary__3videos__image:hover
  .ViewedSectionSecondary__3videos__videoicon_overlay {
  opacity: 1;
}
.ViewedSectionSecondary__2videos__main img {
  /* height: 350px;
  min-height: 350px;
  max-height: 350px; */
}
.ViewedSectionSecondary__2videos__main:hover img {
  transform: scale(1.1);
}
.ViewedSectionSecondary__2videos__main:hover img {
  transform: scale(1.1);
}
.ViewedSectionSecondary__2videos__image:hover,
.ViewedSectionSecondary__3videos__image:hover {
  cursor: pointer;
}
.ViewedSectionSecondary__3videos__text_description {
  text-align: center;
}
.ViewedSectionSecondary__3videos__text_description:hover {
  color: var(--mainYellow);
  cursor: pointer;
}

.ViewedSectionSecondary__3videos__text_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.ViewedSectionSecondary__3videos__text_actions p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

/* media Queeries */
@media (max-width: 750px) {
  /* .ViewedSectionSecondary {
    width: 100%;
    margin: 0 10px 0 10px;
  } */
}
@media (max-width: 550px) {
}
